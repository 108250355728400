import { IForecastingResults } from 'app/shared/model/forecasting-results.model';
import React, { useState, useEffect, useRef } from 'react';
import { Icon, Table, Label, Button, Header, Segment, Accordion, TableBody, TableHeader, TableRow, TableCell, Message, Input, AccordionContent } from 'semantic-ui-react';
import ModalWithChart from './forecasting-results-modal';
import { IDataset } from 'app/shared/model/dataset.model';
import { IUnivariateDataPoint } from 'app/shared/model/univariate-datapoint.model';
import {saveModel, updatePredictions} from '../forecasting.reducer';
import { IForecastingForm } from 'app/shared/model/forecasting.model';

const Row = (props) => {
    const { status, setResultsModal, timeSeriesMeasureCol, forecastingResults, ref} = props;
    const [open, setOpen] = useState(false);

    return (
      <>
        <Table.Row>
          <Table.Cell>
            <Icon
              name={open ? 'angle up' : 'angle down'}
              onClick={() => setOpen(!open)}
              disabled={false}            
            />
          </Table.Cell>
          <Table.Cell>{`${timeSeriesMeasureCol}`}</Table.Cell>
          <Table.Cell textAlign="center">
            {status === 'waiting' ? (
              <Icon name="ellipsis horizontal" color="orange" />
            ) : status === 'processing' ? (
              <Segment basic loading />
            ) : (
              <Icon name="check" color="green" />
            )}
          </Table.Cell>
          <Table.Cell textAlign="center">{status}</Table.Cell>
        </Table.Row>
            
          <Table.Row>
            <Table.Cell colSpan={4}>                
                {open &&(
                  <>
                    <Segment style={{ display: 'flex', gap: '1rem', alignItems: 'center', width: '100%', margin: 'auto' }}>
                      <Header style={{ marginRight: 'auto' }} as="h4">Evaluation Metrics</Header>
                      <Button icon="eye" onClick={() => setResultsModal(name)} />
                    </Segment>
                    <Table compact celled>
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell>Model</Table.HeaderCell>
                          <Table.HeaderCell textAlign="center">MAE</Table.HeaderCell>
                          <Table.HeaderCell textAlign="center">MAPE</Table.HeaderCell>
                          <Table.HeaderCell textAlign="center">MSE</Table.HeaderCell>
                          <Table.HeaderCell textAlign="center">RMSE</Table.HeaderCell>
                          <Table.HeaderCell></Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>
                      <TableBody>
                      {Object.keys(forecastingResults.metrics).map(resultName => (
                        <TableRow key={resultName}>
                          <TableCell component="th" scope="row">
                            {resultName}
                          </TableCell>
                          <TableCell align="center">{forecastingResults.metrics[resultName].evaluation['MAE']}</TableCell>
                          <TableCell align="center">{forecastingResults.metrics[resultName].evaluation['MAPE']}</TableCell>
                          <TableCell align="center">{forecastingResults.metrics[resultName].evaluation['MSE']}</TableCell>
                          <TableCell align="center">{forecastingResults.metrics[resultName].evaluation['RMSE']}</TableCell>
                          <TableCell align="center">
                            <div style={{textAlign:"center", margin:'auto'}}>
                              <Button icon="chart line" onClick={() => props.handlePredict(resultName)}/>
                              <Button icon="save" onClick={() => props.handleClick(resultName)} />
                            </div>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                    </Table>
                  </>
                  )} 
            </Table.Cell>
          </Table.Row>
      </>
    );
  };

export interface IForecastingResultsProps {
  dataset: IDataset,
  timeSeries: IUnivariateDataPoint[],
  timeSeriesMeasureCol: string,
  forecastingForm: IForecastingForm,
  forecastingResults: IForecastingResults,
  savedModels: any,
  saveModel: typeof saveModel,
  updatePredictions: typeof updatePredictions,
}

const ForecastingResults = (props: IForecastingResultsProps) => {
  const {dataset, timeSeries, timeSeriesMeasureCol, forecastingForm, forecastingResults, savedModels} = props;

  const [resultsModal, setResultsModal] = useState(null);
  const [openSnack, setOpenSnack] = useState(false);

  const handleSnackOpen = () => {
    setOpenSnack(true);
  };

  const handleSnackClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnack(false);
  };


  const handlePredict = (type) => {
    const timeInterval = forecastingForm.time_interval;
    const futurePredictions = forecastingForm.future_predictions;
    if (!timeSeries || timeSeries.length === 0 || !timeInterval || !futurePredictions || futurePredictions <= 0) {
      // If any required parameter is missing or invalid, return empty array
      return [];
    }
    console.log(futurePredictions);
    // Calculate the interval in milliseconds based on the time interval
    let intervalInMs;
    switch (timeInterval) {
      case '15m':
        intervalInMs = 15 * 60 * 1000; // 15 minutes
        break;
      case '30m':
        intervalInMs = 30 * 60 * 1000; // 30 minutes
        break;
      case '1h':
        intervalInMs = 60 * 60 * 1000; // 1 hour
        break;
      case '6h':
        intervalInMs = 6 * 60 * 60 * 1000; // 6 hours
        break;
      default:
        // Default to 1 hour if time interval is not recognized
        intervalInMs = 60 * 60 * 1000; // 1 hour
    }

    // Calculate the start time for predictions
    const lastTimestamp = timeSeries[timeSeries.length - 1].timestamp;
    const startTime = lastTimestamp + intervalInMs; // Start prediction from the next time interval
  
    // Generate future predictions based on the last n data points
    const predictions = [];
    predictions.push({timestamp: lastTimestamp, value: timeSeries[timeSeries.length - 1].value}); // add last point to predictions
    for (let i = 1; i <= futurePredictions; i++) {
      // Calculate the index of the time series array for the current prediction
      const timeSeriesIndex = timeSeries.length - i;
      
      // Check if the calculated index is valid
      if (timeSeriesIndex >= 0) {
        const nextTimestamp = startTime + (i * intervalInMs);
        const predictedValue = timeSeries[timeSeriesIndex].value;
        predictions.push({ timestamp: nextTimestamp, value: predictedValue });
      }
    }
    props.updatePredictions(predictions);
  };

  const handleClick = (type) => {
    const id = savedModels.length > 0 ? savedModels.length + 1 : 1;
    props.saveModel({ model_name: `${type}-${id}`, model_type: type });
    handleSnackOpen();
  };

  return (
    <div style={{width:'100%'}}>
      <ModalWithChart dataset={dataset} timeSeries={timeSeries} forecastingResults={forecastingResults} resultsModal={resultsModal} setResultsModal={setResultsModal} />
      <Table celled>
        <TableHeader>
          <TableRow>
            <TableCell />
            <TableCell>Measure Name</TableCell>
            <TableCell textAlign="center">Progress</TableCell>
            <TableCell textAlign="center" />
          </TableRow>
        </TableHeader>
        <TableBody>
          {forecastingResults && 
              <Row
                status={'done'}
                setResultsModal={setResultsModal}
                handleClick={handleClick}
                handlePredict={handlePredict}
                timeSeriesMeasureCol={timeSeriesMeasureCol}
                forecastingResults={forecastingResults}
              />
          }
        </TableBody>
      </Table>
      {openSnack && (
        <Message
          onDismiss={() => handleSnackClose()}
          success
          content="Model has been saved"
          header="Success"
        />
      )}
  </div>
  );
};

export default ForecastingResults;
