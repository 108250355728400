import React from 'react';
import { Header, Button, Table, Popup } from 'semantic-ui-react';
import ForecastingPredModal from '../forecasting-prediction/forecasting-prediction-modal';
import { IDataset } from 'app/shared/model/dataset.model';

export interface IForecastingModelSelectionProps {
  dataset: IDataset,
  setNewTrain: any,
  savedModels: any[],
}

const ForecastingModelSelection = (props: IForecastingModelSelectionProps) => {
  const { setNewTrain, savedModels, dataset } = props;

  const handleNewTrain = () => {
    setNewTrain(true);
  };

  const handleDelete = modelName => e => {
    // dispatch(deleteModelByName(modelName));
  };

  const handleInference = (model) => {
    // const timeInterval = forecastingForm.time_interval;
    // const futurePredictions = forecastingForm.future_predictions;
    // if (!timeSeries || timeSeries.length === 0 || !timeInterval || !futurePredictions || futurePredictions <= 0) {
    //   // If any required parameter is missing or invalid, return empty array
    //   return [];
    // }
    // console.log(futurePredictions);
    // // Calculate the interval in milliseconds based on the time interval
    // let intervalInMs;
    // switch (timeInterval) {
    //   case '15m':
    //     intervalInMs = 15 * 60 * 1000; // 15 minutes
    //     break;
    //   case '30m':
    //     intervalInMs = 30 * 60 * 1000; // 30 minutes
    //     break;
    //   case '1h':
    //     intervalInMs = 60 * 60 * 1000; // 1 hour
    //     break;
    //   case '6h':
    //     intervalInMs = 6 * 60 * 60 * 1000; // 6 hours
    //     break;
    //   default:
    //     // Default to 1 hour if time interval is not recognized
    //     intervalInMs = 60 * 60 * 1000; // 1 hour
    // }

    // // Calculate the start time for predictions
    // const lastTimestamp = timeSeries[timeSeries.length - 1].timestamp;
    // const startTime = lastTimestamp + intervalInMs; // Start prediction from the next time interval
  
    // // Generate future predictions based on the last n data points
    // const predictions = [];
    // predictions.push({timestamp: lastTimestamp, value: timeSeries[timeSeries.length - 1].value}); // add last point to predictions
    // for (let i = 1; i <= futurePredictions; i++) {
    //   // Calculate the index of the time series array for the current prediction
    //   const timeSeriesIndex = timeSeries.length - i;
      
    //   // Check if the calculated index is valid
    //   if (timeSeriesIndex >= 0) {
    //     const nextTimestamp = startTime + (i * intervalInMs);
    //     const predictedValue = timeSeries[timeSeriesIndex].value;
    //     predictions.push({ timestamp: nextTimestamp, value: predictedValue });
    //   }
    // }
    // props.updatePredictions(predictions);
  };

  return (
    <>
      <ForecastingPredModal />
      <div style={{ borderBottom: '1px solid rgba(0,0,0,0.3)', paddingBottom: '10px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Header as='h3' style={{ fontWeight: 'bold', color: 'rgba(0,0,0,0.87)' }}>
          Saved Models
        </Header>
      </div>
      <div style={{ textAlign: 'center', padding: '10px', overflowY: 'auto' }}>
        <Table celled selectable compact>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Model Name</Table.HeaderCell>
              <Table.HeaderCell>Actions</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {savedModels && savedModels.map(model => (
              <Table.Row key={model.model_name}>
                <Table.Cell>{model.model_name}</Table.Cell>
                <Table.Cell textAlign="right">
                    <Button icon='chart line' onClick={() => handleInference(model)}/>
                    <Button icon='trash' onClick={() => handleDelete(model.model_name)} />
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
        <div style={{textAlign:'center', margin:'auto'}}>
          <Button size="small" color="black" onClick={handleNewTrain}>
              Train a new model
          </Button>
        </div>
    </div>
    </>
  );
};

export default ForecastingModelSelection;
