
import { IDataset } from 'app/shared/model/dataset.model';
import { IUnivariateDataPoint } from 'app/shared/model/univariate-datapoint.model';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from "highcharts/highcharts";
import HighchartsMore from 'highcharts/highcharts-more';
import React, { useEffect, useState } from 'react';
import { Button, Dropdown, Icon, Label, Segment } from 'semantic-ui-react';
import { updateFrequency, updateOpenTimeSeriesModal, updateTimeSeries, updateTimeSeriesMeasure } from './visualizer.reducer';
HighchartsMore(Highcharts);

export interface IChartTSProps {
    dataset: IDataset,
    timeSeries: IUnivariateDataPoint[];
    timeSeriesResultsLoading: boolean,
    timeSeriesMeasureCol: string,
    frequency: number,
    updateOpenTimeSeriesModal: typeof updateOpenTimeSeriesModal,
    updateTimeSeries: typeof updateTimeSeries,
    updateFrequency: typeof updateFrequency,
    updateTimeSeriesMeasure: typeof updateTimeSeriesMeasure,
}


export const TimeSeriesChart = (props: IChartTSProps) => {
    const {dataset, timeSeries, timeSeriesMeasureCol, frequency} = props;
    const [frequencyData, setFrequencyData] = useState("15m");
    // on initialization
    useEffect(() => {
        props.updateTimeSeriesMeasure(dataset.id, dataset.measure0);
    }, []);
    
    const [seriesData, setSeriesData] = useState(null);
    
    const getTimeIntervalFromFrequency = () => {
        switch(frequency){
          case 900:
            return '15m';
          case 1800:
            return '30m';
          case 3600:
            return '1h';
          case 21600:
            return '6h';
          default:
            return '15m';
        }
      }

      
    const handleTimeInterval = (data) => {
        let frequency = 900;
        setFrequencyData(data.value);
        switch(data.value) {
          case "15m":
            frequency = 900;
            break;
          case "30m":
            frequency = 1800;
            break;
          case "1h":
            frequency = 3600;
            break;
          case "6h":
            frequency = 21600;
            break;
          default:
            frequency = 900;
        }
        props.updateFrequency(dataset.id, frequency);
      }

    useEffect(() => {
        if (timeSeries) {
            const formattedData = timeSeries.map(datapoint => [
                datapoint.timestamp,
                datapoint.value
            ]);
            setSeriesData(formattedData);
        }
    }, [timeSeries]);

    useEffect(() => {
        setFrequencyData(getTimeIntervalFromFrequency());
    }, [frequency])
    const handleMeasureChange = (e, {value}) =>  {
        props.updateTimeSeriesMeasure(dataset.id, value); 
        props.updateTimeSeries(dataset.id)};

    const handleOpenModal = () => {
        props.updateOpenTimeSeriesModal(true);
    };

    return (
        <Segment id='chart-container' raised textAlign='center'>    
            <Button
                icon
                style={{ position: 'absolute', top: 0, right: 0, border: 'none', backgroundColor: 'transparent' }}
                onClick={handleOpenModal}
                title="Click for full view"
            >
                <Icon name='external' />
            </Button>
            <div style={{margin: "auto"}}>
                <Label.Group>
                <span>Time series data for <Dropdown
                    scrolling={true}
                    options={[{text: dataset.measure0, value: dataset.measure0}, {
                        text: dataset.measure1,
                        value: dataset.measure1
                    }]} inline value={timeSeriesMeasureCol} onChange={handleMeasureChange}/></span>
                </Label.Group>
            </div>
            <div style={{display:'flex', justifyContent:'right', marginLeft: "auto"}}>
            <Dropdown
                    text="Freq."
                    options={[
                    { key: '15m', text: '15m', value: '15m' },
                    { key: '30m', text: '30m', value: '30m' },
                    { key: '1h', text: '1h', value: '1h' },
                    { key: '6h', text: '6h', value: '6h' }
                    ]}
                    value={frequencyData}
                    onChange={(e, data) => {
                        handleTimeInterval(data)
                    }}
                />
            </div>
            <HighchartsReact
                highcharts={Highcharts}
                containerProps={{className: "chartContainer"}}
                allowChartUpdate={true}
                immutable={false}
                updateArgs={[true, true, true]}
                options= {{
                    chart: {
                        type: 'line',
                        height: '300px',
                        marginTop: 10,
                        paddingTop: 0,
                        marginBottom: 50,
                        plotBorderWidth: 1,
                    },
                    xAxis: {
                        ordinal: false,
                        type: "datetime",
                        tickPixelInterval: 100,
                        title: {
                            // text: 'Date',
                            style: {
                            fontSize: "1.1em"
                            },
                        },
                    },
                    yAxis: {
                        title: {
                            text: timeSeriesMeasureCol,
                            style: {
                            fontSize: "1.1em"
                            }
                        },
                    },
                    colorAxis: {
                        min: 0,
                        minColor: '#FFFFFF',
                        maxColor: '#2185d0'
                    },
                    plotOptions: {
                        line: {
                            cursor: 'pointer',
                        },
                    },
                    legend: {
                        enabled: false
                    },
                    title: null,
                    series: {
                        type: 'line',
                        data: seriesData,
                    },
                    tooltip: {
                        formatter: function () {
                          // The first returned item is the header, subsequent items are the
                          // points
                          return ['<b>' + new Date(this.x) + '</b>'].concat(
                            this.points
                              ? this.points.map(function (point) {
                                  let ss = `<div><span style="color: ${point.color}; font-size: 12px; margin-right:5px;">●</span>  ${
                                    point.series.name
                                  }: ${point.y.toFixed(2)}</div>`;
                                  ss += point.point.tt ? `<br>${point.point.tt}</br>` : '';
                                  return ss;
                                })
                              : []
                          );
                        },
                        split: true,
                      },
                }}
            />
        </Segment>
    );
}