import React, { Dispatch, SetStateAction } from 'react';
import { IForecastingForm } from 'app/shared/model/forecasting.model';
import Features from './forecasting-features';
import { IDataset } from 'app/shared/model/dataset.model';
import { Segment, Header } from 'semantic-ui-react';

export interface IForecastingFeatureExtrProps {
  dataset: IDataset,
  timeSeriesMeasureCol: string,
  forecastingForm: IForecastingForm,
  setForecastingForm: Dispatch<SetStateAction<IForecastingForm>>,
}

const ForecastingFeatureExtr = (props: IForecastingFeatureExtrProps) => {
  const { dataset, timeSeriesMeasureCol,forecastingForm, setForecastingForm } = props;

  return (
    <>
      <Features dataset={dataset} timeSeriesMeasureCol={timeSeriesMeasureCol} forecastingForm={forecastingForm} setForecastingForm={setForecastingForm} />
    </>
  );
};

export default ForecastingFeatureExtr;
