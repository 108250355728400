import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Segment, Header, Dropdown, Radio, Popup } from 'semantic-ui-react';
import MultiRangeSlider from "multi-range-slider-react";
import { updateFrequency } from '../../visualizer/visualizer.reducer';
import { IDataset } from 'app/shared/model/dataset.model';
import { IForecastingForm } from 'app/shared/model/forecasting.model';

export interface IForecastingDataPrepProps {
  dataset: IDataset,
  forecastingForm: IForecastingForm,
  updateFrequency: typeof updateFrequency,
  setForecastingForm: Dispatch<SetStateAction<IForecastingForm>>,
}

const ForecastingDataPrep = (props: IForecastingDataPrepProps) => {
  const { dataset, forecastingForm } = props;
  const [predefinedSplit, setPredefinedSplit] = useState('balanced'); // State to store the selected predefined split


  const handleDataSplit = vals => {
    const minValue = vals.minValue;
    const maxValue = vals.maxValue;
    props.setForecastingForm(state => ({
      ...state,
      dataSplit: [minValue, maxValue - minValue, 100 - maxValue],
    }));
  };

  const handleTimeInterval = (data) => {
    let frequency = 900;
    switch(data.value) {
      case "15m":
        frequency = 900;
        break;
      case "30m":
        frequency = 1800;
        break;
      case "1h":
        frequency = 3600;
        break;
      case "6h":
        frequency = 21600;
        break;
      default:
        frequency = 900;
    }
    props.updateFrequency(dataset.id, frequency);
    props.setForecastingForm(state => ({...state, time_interval: data.value}));
  }

  const handleRadioChange = (value) => {
    // Update the predefined split value
    setPredefinedSplit(value);

    // Update the multibar slider values based on the selected radio option
    let minValue, maxValue;

    switch (value) {
      case 'balanced':
        minValue = 60;
        maxValue = 80;
        break;
      case 'aggressive':
        minValue = 80;
        maxValue = 90;
        break;
      case 'conservative':
        minValue = 50;
        maxValue = 75;
        break;
      // Add more cases for other predefined splits if needed
      default:
        // Default to balanced split
        minValue = 60;
        maxValue = 80;
    }

    props.setForecastingForm(state => ({
      ...state,
      dataSplit: [minValue, maxValue - minValue, 100 - maxValue],
    }));
  };

  const tooltipContent = {
    balanced: 'Balanced split: Allocates equal proportions for training, validation, and test sets.',
    aggressive: 'Aggressive split: Reserves the majority of data for training and small portions for validation and test sets.',
    conservative: 'Conservative split: Takes a cautious approach by allocating relatively large portions for validation and test sets.',
  };

  return (
    <>
    <Segment className={'basic-values'} style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '1rem', textAlign: 'center', border: 'none' }}>
      <Segment style={{ display: 'flex', gap: '1rem', alignItems: 'center', width: '100%', margin: 'auto' }}>
          <Header as='h5' style={{ fontSize: '16px' , marginRight: 'auto' }}>
            Time interval
          </Header>
          <Dropdown
            selection
            options={[
              { key: '15m', text: '15m', value: '15m' },
              { key: '30m', text: '30m', value: '30m' },
              { key: '1h', text: '1h', value: '1h' },
              { key: '6h', text: '6h', value: '6h' }
            ]}
            value={forecastingForm.time_interval}
            onChange={(e, data) => {
                handleTimeInterval(data)
            }}
          />
      </Segment>
      <Segment style={{display: 'flex', gap:'1rem', flexDirection: 'column'}}>
        <div style={{ display: 'flex', gap: '1rem', alignItems: 'center', width: '100%', margin: 'auto' }}>
          <Header as='h5' style={{ fontSize: '16px' , marginRight: 'auto' }}>
            Data Split
          </Header>
          {forecastingForm.dataSplit && <MultiRangeSlider
            min={0}
            max={100}
            step={1}
            minValue={forecastingForm.dataSplit[0]}
            maxValue={forecastingForm.dataSplit[0] + forecastingForm.dataSplit[1]}
            ruler={false}
            label={true}
            labels={["0", "", "", "60", "80", "100"]} 
            barLeftColor='#4CAF50' 
            barInnerColor='#FF6B6B' 
            barRightColor='#536DFE'
            thumbLeftColor='white'
            thumbRightColor='white'
            style={{ border: 'none', boxShadow: 'none', width:'80%', padding: '15px 10px' }}
            onChange={(e) => {
              handleDataSplit(e);
            }}
          />
          }
        </div>
        <div style={{ display: 'flex', gap: '2rem', alignItems: 'right', width: '100%', flexDirection: 'row-reverse'}}>
        <Popup
          content={tooltipContent.balanced}
          trigger={<Radio
            label='Balanced'
            name='radioGroup'
            value='balanced'
            checked={predefinedSplit === 'balanced'}
            onChange={() => handleRadioChange('balanced')}
          />}
        />
        <Popup
          content={tooltipContent.aggressive}
          trigger={<Radio
            label='Aggressive'
            name='radioGroup'
            value='aggressive'
            checked={predefinedSplit === 'aggressive'}
            onChange={() => handleRadioChange('aggressive')}
          />}
        />
        <Popup
          content={tooltipContent.conservative}
          trigger={<Radio
            label='Conservative'
            name='radioGroup'
            value='conservative'
            checked={predefinedSplit === 'conservative'}
            onChange={() => handleRadioChange('conservative')}
          />}
        />
      </div>
      </Segment>
    </Segment>
    </>
  );
};

export default ForecastingDataPrep;
