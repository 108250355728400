import { MAX_ZOOM } from "app/config/constants";
import { BeautifyIcon } from "app/modules/visualizer/beautify-marker/leaflet-beautify-marker-icon";
import {
  getRow,
  updateDrawnRect,
  updateMapBounds,
} from 'app/modules/visualizer/visualizer.reducer';
import { ICluster } from "app/shared/model/cluster.model";
import { IDataset } from "app/shared/model/dataset.model";
import L from 'leaflet';
import 'leaflet-draw';
import 'leaflet-draw/dist/leaflet.draw.css';
import React, { useEffect, useState } from 'react';
import { MapContainer, Marker, Popup, TileLayer, ZoomControl } from 'react-leaflet';
import { HeatmapLayer } from "react-leaflet-heatmap-layer-v3/lib";
import MapSearch from './map-search';
import './visualizer.scss';

export interface IMapProps {
  id: any,
  clusters: ICluster[],
  dataset: IDataset,
  zoom: any,
  viewRect: any,
  updateMapBounds: typeof updateMapBounds,
  updateDrawnRect: typeof updateDrawnRect,
  getRow: typeof getRow,
  row: string[],
  expandedClusterIndex: number,
}

const rsrpIntensityExtractor = (dataset: IDataset, rsrp_rscp_rssi) => {
  if ((dataset.measure0 === 'rsrp_rscp_rssi' || dataset.measure1 === 'rsrp_rscp_rssi') && rsrp_rscp_rssi != null) {
    if (rsrp_rscp_rssi < 0) rsrp_rscp_rssi = -rsrp_rscp_rssi
    // rsrp is excellent(pure green color) when it is  bigger/equal to -70 and on cell edge(pure red color) when it is smaller/equal to -100
    let min = 70;
    let max = 100;
    if (rsrp_rscp_rssi < min) rsrp_rscp_rssi = min;
    if (rsrp_rscp_rssi > max) rsrp_rscp_rssi = max;
    let percentage = ((rsrp_rscp_rssi - min) * 100) / (max - min);
    percentage = percentage / 100;
    return percentage;
  }
  else {
    return 1;
  }
}

const generateRsrpColor = (dataset: IDataset, rsrp_rscp_rssi) => {
  if ((dataset.measure0 === 'rsrp_rscp_rssi' || dataset.measure1 === 'rsrp_rscp_rssi') && rsrp_rscp_rssi != null) {
    const percentage = rsrpIntensityExtractor(dataset, rsrp_rscp_rssi);
    // hue0: the hue value of the color you want to get when the percentage is 0, value 120 is green
    let hue0 = 120;
    // hue1: the hue value of the color you want to get when the percentage is 1, value 0 is red
    let hue1 = 0;
    let hue = (percentage * (hue1 - hue0)) + hue0;
    return 'hsl(' + hue + ', 100%, 70%)';
  }
  else {
    return "rgba(212,62,42)";
  }

}

const fetchIcon = (count, clickable, backgroundColor) => {

  if (count === 1) return BeautifyIcon.icon({
    iconShape: 'doughnut',
    isAlphaNumericIcon: false,
    backgroundColor: "rgba(212,62,42)",
    borderColor: "#ffffff",
    borderWidth: 2,
    iconSize: [18, 18],
    hasBadge: false,
    iconStyle: clickable && 'cursor: pointer',
  });

  const borderColor = count < 100 ? 'rgba(102, 194, 164, 0.5)' :
    count < 1000 ? 'rgba(44, 162, 95, 0.5)' : 'rgba(0, 109, 44, 0.5)';


  return BeautifyIcon.icon({
    customClasses: 'cluster',
    isAlphaNumericIcon: true,
    textColor: "black",
    text: count,
    hasBadge: false,
    badgeText: "",
    backgroundColor,
    borderColor,
    borderWidth: 5,
    iconSize: [40, 40],
    iconStyle: clickable && 'cursor: pointer',
  });
};


const SinglePoint = (props: any) => {
  const { dataset, point, coordinates, row } = props;
  return (
    <Marker key={point[2]} icon={fetchIcon(1, 1, null)} position={[coordinates[1], coordinates[0]]}>
      <Popup onOpen={() => {
        props.getRow(dataset.id, point[3]);
      }}>
        <div style={{
          maxHeight: "200px",
          overflowY: "scroll"
        }}>{row && dataset.headers && dataset.headers.map((colName, colIndex) => {
          if (colName === "id") return <></>

          let val = row[colIndex];
          if (val == null) val = "";
          return (
            <div key={colIndex}>
              <span>
                <b>{colName}: </b>{val.startsWith("http") ? <a href={val}>{val}</a> : val}
              </span>
              <br></br>
            </div>
          )
        })}</div>
      </Popup>
    </Marker>);
};


export const Map = (props: IMapProps) => {

  const { clusters, dataset, row, viewRect, zoom } = props;

  const [clusterMap, setClusterMap] = useState(true);
  const [map, setMap] = useState(null);
  const [points, setPoints] = useState(null);

  const toggleClusterMap = () => {
    setClusterMap(prevState => !prevState);
  };

  useEffect(() => {
    if (!map) return;

    const drawnItems = new L.FeatureGroup();
    map.addLayer(drawnItems);

    // @ts-ignore
    const drawControl = new L.Control.Draw({
      position: 'topright',
      draw: {
        polyline: false,
        polygon: false,
        circle: false,
        marker: false,
        circlemarker: false,
      },
      edit: {
        featureGroup: drawnItems,
        edit: false,
        remove: true,
      },
    });

    map.addControl(drawControl);

    // @ts-ignore
    map.on(L.Draw.Event.CREATED, e => {
      const type = e.layerType;
      const layer = e.layer;
      drawnItems.clearLayers();
      drawnItems.addLayer(layer);
      props.updateDrawnRect(props.id, layer._bounds);
    });

    // @ts-ignore
    map.on(L.Draw.Event.DELETED, e => {
      props.updateDrawnRect(props.id, null);
    });

    map.on('moveend', e => {
      props.updateMapBounds(props.id, e.target.getBounds(), e.target.getZoom());
    });
    // Custom map change controls
    // @ts-ignore
    const viewChangeControl = L.control({ position: 'topright' });
    viewChangeControl.onAdd = function () {
      const div = L.DomUtil.create('div', 'leaflet-bar leaflet-control');

      const changeViewButton = L.DomUtil.create('div', 'leaflet-bar-part', div);
      changeViewButton.style.backgroundColor = '#fff';
      changeViewButton.style.borderRadius = '4px';
      changeViewButton.style.padding = '6px';
      changeViewButton.style.cursor = 'pointer';
      changeViewButton.title = 'Change View';
      changeViewButton.innerHTML = `
        <i class="icon eye"></i>
      `;
      changeViewButton.onclick = () => {
        toggleClusterMap();
      };
      return div;
    };
    viewChangeControl.addTo(map);

    // legend
    // @ts-ignore
    var legend = L.control({ position: 'topright' });

    legend.onAdd = function (map) {
      var div = L.DomUtil.create("div", "legend");
      div.innerHTML += '<div class="color-block" style="background-color: #FC6666;"></div> <span class="label">Poor</span><br>';
      div.innerHTML += '<div class="color-block" style="background-color: orange;"></div> <span class="label">Fair</span><br>';
      div.innerHTML += '<div class="color-block" style="background-color: #FFF966;"></div> <span class="label">Good</span><br>';
      div.innerHTML += '<div class="color-block" style="background-color: #99FF99;"></div> <span class="label">Excellent</span>';
      return div;  
    };

    legend.addTo(map);
    map.fitBounds([[viewRect.lat[0], viewRect.lon[0]], [viewRect.lat[1], viewRect.lon[1]]]);
  }, [map])

  const getPoints = (clusters) => {
    return clusters.map(cluster => {
      return cluster.properties.points.map(point => {
        return [point[0], point[1], point[4]]
      })
    }).flat(1);
  }

  useEffect(() => {
    if (clusters) setPoints(getPoints(clusters));
  }, [clusters]);


  return <><MapContainer scrollWheelZoom={true} whenCreated={setMap} zoomControl={false} maxZoom={MAX_ZOOM}>
    <TileLayer
      attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
    />
    {clusterMap && map && clusters && clusters.map((cluster, index) => {
      // every cluster point has coordinates
      // the point may be either a cluster or a single point
      const {
        totalCount, points, rsrp_rscp_rssi
      } = cluster.properties;
      if (totalCount === 1) {
        return <SinglePoint dataset={dataset} point={points[0]} row={row} getRow={props.getRow}
          coordinates={cluster.geometry.coordinates} />
      }
      return <Marker key={"cluster" + index}
        position={[cluster.geometry.coordinates[1], cluster.geometry.coordinates[0]]}
        icon={fetchIcon(totalCount, true, generateRsrpColor(dataset, rsrp_rscp_rssi))}
      >
        <Popup>
          <div style={{
            maxHeight: "200px",
            //  overflowY: "scroll"
          }}>
            <div>
              <span>
                <b>{dataset.measure0}:</b> {cluster.properties[dataset.measure0] && cluster.properties[dataset.measure0].toFixed(4)}
              </span>
              <br></br>
            </div>
            <div>
              <span>
                <b>{dataset.measure1}:</b> {cluster.properties[dataset.measure1] && cluster.properties[dataset.measure1].toFixed(4)}
              </span>
              <br></br>
            </div>
            {dataset.dimensions.map((dim) => (
              <div key={dim}>
                <span>
                  <b>{dim}:</b> {
                    Array.isArray(cluster.properties[dim]) ?
                      // Check if there are more than 10 items
                      cluster.properties[dim].length > 10 ?
                        // Join the first 10 items and add ellipsis
                        `${cluster.properties[dim].slice(0, 10).join(', ')}...` :
                        // Join all items if 10 or fewer
                        cluster.properties[dim].join(', ')
                      :
                      // Handle the case where it's not an array
                      cluster.properties[dim]
                  }
                </span>
                <br />
              </div>
            ))}
          </div>
        </Popup>
      </Marker>;
    })}
    {!clusterMap && map && points &&
      <HeatmapLayer
        fitBoundsOnLoad
        fitBoundsOnUpdate={false}
        points={points}
        latitudeExtractor={r => r[0]}
        longitudeExtractor={r => r[1]}
        intensityExtractor={r => {
          return rsrpIntensityExtractor(dataset, r[2]);
        }}
        radius={zoom}
        // points={clusters.filter(c => c.properties.totalCount > 1)}
        // latitudeExtractor={(r:ICluster) => r.geometry.coordinates[1]}
        // longitudeExtractor={(r:ICluster) =>  r.geometry.coordinates[0]}
        // intensityExtractor={(r:ICluster) => {
        //   return rsrpIntensityExtractor(dataset, r.properties.rsrp_rscp_rssi);
        // }}
        useLocalExtrema={false}
        gradient={{0: "#00FFFF", 0.2: '#00FF00', 0.4:'#66FF33', 0.6: '#FFFF00', 0.8: '#FF6600', 1.0: '#FF0000'}}
      />
    }

    <ZoomControl position="topright" />
  </MapContainer>
    <div className="search-bar-ui">
      <MapSearch map={map} />
    </div>
  </>

};

export default Map;
