import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import ForecastingTrainStepper from './forecasting-landing/forecasting-train-stepper';
import ForecastingModelSelection from './forecasting-landing/forecasting-model-selection';
import { Segment } from 'semantic-ui-react';
import { getSavedModels, saveModel, startTraining, toggleTrainStepper, updatePredictions } from '../forecasting/forecasting.reducer';
import {updateFrequency} from '../visualizer/visualizer.reducer';
import { IDataset } from 'app/shared/model/dataset.model';
import { ITimeRange } from 'app/shared/model/time-range.model';
import { IForecastingResults } from 'app/shared/model/forecasting-results.model';
import { IUnivariateDataPoint } from 'app/shared/model/univariate-datapoint.model';
import { IForecastingForm } from 'app/shared/model/forecasting.model';

export interface IForecastingProps {
  dataset: IDataset,
  timeRange: ITimeRange,
  timeSeries: IUnivariateDataPoint[],
  frequency: number,
  savedModels: any[],
  timeSeriesMeasureCol: string,
  forecastingResults: IForecastingResults,
  forecastingForm: IForecastingForm,
  getSavedModels: typeof getSavedModels,
  updateFrequency: typeof updateFrequency,
  startTraining: typeof startTraining,
  saveModel: typeof saveModel,
  updatePredictions: typeof updatePredictions,
  setForecastingForm: Dispatch<SetStateAction<IForecastingForm>>,
  toggleTrainStepper: typeof toggleTrainStepper,
}
const Forecasting = (props: IForecastingProps) => {
  const {savedModels, dataset, forecastingForm, forecastingResults, timeRange, frequency, timeSeries, timeSeriesMeasureCol} = props;
  const [newTrain, setNewTrain] = useState(false);

  useEffect(() => {
    props.updatePredictions([]);
    props.getSavedModels();
  }, [])

  return (
    <Segment>
      {!newTrain && <ForecastingModelSelection dataset={dataset} savedModels={savedModels} setNewTrain={setNewTrain} />}
      {newTrain && <ForecastingTrainStepper dataset={dataset} savedModels={savedModels} timeSeries={timeSeries} timeRange = {timeRange} frequency = {frequency}
       updateFrequency={props.updateFrequency} startTraining={props.startTraining} saveModel={props.saveModel}
       updatePredictions = {props.updatePredictions} forecastingForm={forecastingForm} setForecastingForm={props.setForecastingForm}
      setNewTrain={setNewTrain} toggleTrainStepper={props.toggleTrainStepper} timeSeriesMeasureCol={timeSeriesMeasureCol} forecastingResults={forecastingResults}/>}
    </Segment>
  );
};

export default Forecasting;
