import React, { Dispatch, SetStateAction, useState } from 'react';
import {
  IForecastingForm,
  ILGBMDefault,
  ILGBMDefaultFT,
  ILinearRegressionDefault,
  IXGBoostDefault,
  IXGBoostDefaultFT,
} from 'app/shared/model/forecasting.model';
import { Table, Checkbox, Button, Segment, Header, Dropdown, Input } from 'semantic-ui-react';

import ForecastingAlgModal from './forecasting-alg-selection-modal';

export interface IForecastingTrainProps {
  forecastingForm: IForecastingForm;
  setForecastingForm: Dispatch<SetStateAction<IForecastingForm>>;
}

const ForecastingAlgSelection = (props: IForecastingTrainProps) => {
  const { forecastingForm, setForecastingForm } = props;
  const [open, setOpen] = useState(false);
  const [fineTuneSelection, setFineTuneSelection] = useState([]);
  const [selectedAlgo, setSelectedAlgo] = useState(null);
  const [customValue, setCustomValue] = useState('');
  const [customNumber, setCustomNumber] = useState(1);

  const handleConfigClick = algName => e => {
    setSelectedAlgo(algName);
    setOpen(!open);
    console.log(open);
  };

  const handleFineTuneCheckbox = algName => e => {
    const tempForm = forecastingForm;
    if (e.target.checked) {
      setFineTuneSelection(state => [...state, algName]);
    } else {
      setFineTuneSelection(fineTuneSelection.filter(n => n !== algName));
    }
    if (algName === 'XGBoost') {
      if (e.target.checked) {
        setForecastingForm(state => ({ ...state, algorithms: { ...state.algorithms, XGBoost: IXGBoostDefaultFT } }));
      } else {
        delete tempForm.algorithms['XGBoost'];
        setForecastingForm(state => ({ ...state, algorithms: { ...state.algorithms, XGBoost: IXGBoostDefault } }));
      }
    } else if (algName === 'LGBM') {
      if (e.target.checked) {
        setForecastingForm(state => ({ ...state, algorithms: { ...state.algorithms, LGBM: ILGBMDefaultFT } }));
      } else {
        delete tempForm.algorithms['LGBM'];
        setForecastingForm(state => ({ ...state, algorithms: { ...state.algorithms, LGBM: ILGBMDefault } }));
      }
    }
  };

  const handleAlgorithmSelection = algName => {
    const tempForm = forecastingForm;
    if (algName === 'XGBoost') {
      if (!(algName in tempForm.algorithms)) {
        setForecastingForm(state => ({ ...state, algorithms: { ...state.algorithms, XGBoost: IXGBoostDefault } }));
      } else {
        delete tempForm.algorithms['XGBoost'];
        setForecastingForm(state => ({ ...state, algorithms: tempForm.algorithms }));
      }
    } else if (algName === 'LGBM') {
      if (!(algName in tempForm.algorithms)) {
        setForecastingForm(state => ({ ...state, algorithms: { ...state.algorithms, LGBM: ILGBMDefault } }));
      } else {
        delete tempForm.algorithms['LGBM'];
        setForecastingForm(state => ({ ...state, algorithms: tempForm.algorithms }));
      }
    } else if (algName === 'LinearRegression') {
      if (!(algName in tempForm.algorithms)) {
        setForecastingForm(state => ({ ...state, algorithms: { ...state.algorithms, LinearRegression: ILinearRegressionDefault } }));
      } else {
        delete tempForm.algorithms['LinearRegression'];
        setForecastingForm(state => ({ ...state, algorithms: tempForm.algorithms }));
      }
    }
  };

  const handleCustomInputChange = (event) => {
    const value = event.target.value;
    // Validate input to ensure it's a number between 1 and 30
    if (/^\d+$/.test(value) && value >= 1 && value <= 30) {
      setCustomValue(value);
      setCustomNumber(parseInt(value));
    } else {
      setCustomValue('');
      setCustomNumber(1);
    }
    props.setForecastingForm(state => ({...state, future_predictions: value}))
  };

  const humanReadableDuration = (number) => {
    const intervals = ['15m', '30m', '1h', '6h']; // Time intervals
    const selectedInterval = forecastingForm.future_predictions; // Selected interval
    const index = intervals.indexOf(forecastingForm.time_interval);
    if (index !== -1) {
      const totalMinutes = number * (index === 0 ? 15 : index === 1 ? 30 : index === 2 ? 60 : 360);
      const days = Math.floor(totalMinutes / (24 * 60));
      const remainingMinutes = totalMinutes % (24 * 60);
      const hours = Math.floor(remainingMinutes / 60);
      const minutes = remainingMinutes % 60;
      if (days > 0) {
        return `${days}d ${hours}h ${minutes}m`;
      } else {
        return `${hours}h ${minutes}m`;
      }
    }
    return '';
  };
  return (
    <>
      <ForecastingAlgModal
        open={open}
        setOpen={setOpen}
        forecastingForm={forecastingForm}
        setForecastingForm={setForecastingForm}
        selectedAlgo={selectedAlgo}
        fineTuneSelection={fineTuneSelection}
        setFineTuneSelection={setFineTuneSelection}
      />
    <Segment className={'basic-values'} style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '1rem', textAlign: 'center', border: 'none' }}>
      <Segment>
      <Table basic='very' size='small'>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell style={{ fontSize: '16px'}}>Algorithm</Table.HeaderCell>
              <Table.HeaderCell style={{ fontSize: '16px'}} textAlign='center'>Parameters</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {['XGBoost', 'LGBM', 'LinearRegression'].map(row => (
              <Table.Row key={row}>
                <Table.Cell>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Checkbox
                      checked={row in forecastingForm.algorithms}
                      onChange={() => handleAlgorithmSelection(row)}
                    />
                    <span style={{ marginLeft: '5px' }}>{row}</span>
                  </div>
                </Table.Cell>
                <Table.Cell textAlign='center'>
                  <Button
                    basic
                    compact
                    size='small'
                    disabled={!Object.keys(forecastingForm.algorithms).includes(row)}
                    onClick={handleConfigClick(row)}
                  >
                    Configure
                  </Button>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </Segment>
      <Segment style={{ display: 'flex', alignItems: 'center', width: '100%', margin: 0 }}>
            <Header as='h5' style={{ fontSize: '16px' , marginRight: 'auto' }}>
              Predictions
            </Header>
            <Input
              placeholder='Enter number (1-30)'
              value={customValue}
              onChange={handleCustomInputChange}
              style={{marginRight:'1rem'}}
            />
            <span>{customValue && humanReadableDuration(customNumber)}</span>
      </Segment>
    </Segment>  
    </>
  );
};

export default ForecastingAlgSelection;
