import { IForecastingForm } from "app/shared/model/forecasting.model";
import { IForecastingResults } from 'app/shared/model/forecasting-results.model';
import { REQUEST, SUCCESS } from "app/shared/reducers/action-type.util";
import axios from "axios";
import { v4 as uuidv4 } from 'uuid';
import { IUnivariateDataPoint } from "app/shared/model/univariate-datapoint.model";

export const ACTION_TYPES = {
    FETCH_SAVED_MODELS: 'forecasting/FETCH_SAVED_MODELS',
    START_TRAINING: 'forecasting/START_TRAINING',
    UPDATE_FORECASTING_RESULTS: 'forecasting/UPDATE_FORECASTING_RESULTS',
    SAVE_MODEL: 'forecasting/SAVE_MODEL',
    UPDATE_PREDICTIONS: 'forecasting/UPDATE_PREDICTIONS',
    TOGGLE_TRAIN_STEPPER: 'forecasting/TOGGLE_TRAIN_STEPPER',
}

const initialState = { 
    savedModels: [],
    forecastingLoading: false,
    forecastingResults: null as IForecastingResults,
    predictions: [],
    isInTrainStepper: false,
}

export type ForecastingState = Readonly<typeof initialState>;

export default (state: ForecastingState = initialState, action): ForecastingState => {
    switch (action.type) {
        case ACTION_TYPES.FETCH_SAVED_MODELS:
            return {
                ...state,
                 savedModels: action.payload,
            };
        case REQUEST(ACTION_TYPES.START_TRAINING):
            return {
                ...state,
                forecastingLoading: true,
            };
        case SUCCESS(ACTION_TYPES.START_TRAINING):
            return {
                ...state,
                forecastingLoading: false,
            };
        case ACTION_TYPES.UPDATE_FORECASTING_RESULTS:
            return {
                ...state,
                forecastingResults: action.payload,
            }
        case ACTION_TYPES.SAVE_MODEL:
            return {
                ...state,
                savedModels: [...state.savedModels, action.payload],
            }
        case ACTION_TYPES.UPDATE_PREDICTIONS:
            return {
                ...state,
                 predictions: action.payload,
            }
        case ACTION_TYPES.TOGGLE_TRAIN_STEPPER:
            return {
                ...state,
                isInTrainStepper: action.payload,
            }
        default:
            return state;
    }
}


export const getSavedModels = () => dispatch => { 
    dispatch({
      type: ACTION_TYPES.FETCH_SAVED_MODELS,
      payload: axios.get('api/saved-models'),
    });
  } 

export const startTraining = (config: IForecastingForm) => async (dispatch, getState)=> {
    const state: any = getState();
    const id = uuidv4();
    localStorage.setItem('id', id);
    const response = await axios
        .post(`api/forecasting/train`, {
        id,
        dataPoints: state.visualizer.timeSeries,
        config: JSON.stringify(config),
        })
        .then(res => dispatch(updateForecastingResults(res.data)));
    return response.data;
}


export const getTarget = async (name: string) => {
    const target = { name, id: localStorage.getItem('id') };
    const response = await axios.post(`api/forecasting/target`, target).then(res => res.data as IForecastingResults);
    return response;
};
  
export const updateForecastingResults = (data: IForecastingResults) => ({
    type: ACTION_TYPES.UPDATE_FORECASTING_RESULTS,
    payload: data,
});

export const saveModel = (model: any) => ({
    type: ACTION_TYPES.SAVE_MODEL,
    payload: model,
});

export const updatePredictions = (predictions: any) => ({
    type: ACTION_TYPES.UPDATE_PREDICTIONS,
    payload: predictions,
});

export const toggleTrainStepper = (isInTrainStepper) => ({
    type: ACTION_TYPES.TOGGLE_TRAIN_STEPPER,
    payload:isInTrainStepper,
});