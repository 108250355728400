import { MAX_ZOOM } from 'app/config/constants';
import { IDataset } from 'app/shared/model/dataset.model';
import { AggregateFunctionType } from 'app/shared/model/enumerations/aggregate-function-type.model';
import { IGroupedStats } from 'app/shared/model/grouped-stats.model';
import { IIndexStatus, defaultValue } from 'app/shared/model/index-status.model';
import { IQuery } from 'app/shared/model/query.model';
import { IRectStats } from 'app/shared/model/rect-stats.model';
import { IRectangle } from 'app/shared/model/rectangle.model';
import { FAILURE, REQUEST, SUCCESS } from 'app/shared/reducers/action-type.util';
import axios from 'axios';
import { LatLngBounds } from 'leaflet';
import qs from 'qs';
import Supercluster from 'supercluster';

export const ACTION_TYPES = {
  FETCH_DATASET: 'visualizer/FETCH_DATASET',
  FETCH_DATASET_LIST: 'dataset/FETCH_DATASET_LIST',
  RESET: 'visualizer/RESET',
  UPDATE_MAP_BOUNDS: 'visualizer/UPDATE_MAP_BOUNDS',
  UPDATE_CLUSTERS: 'visualizer/UPDATE_CLUSTERS',
  UPDATE_FACETS: 'visualizer/UPDATE_FACETS',
  UPDATE_GROUP_BY: 'visualizer/UPDATE_GROUP_BY',
  UPDATE_MEASURE: 'visualizer/UPDATE_MEASURE',
  UPDATE_AGG_TYPE: 'visualizer/UPDATE_AGG_TYPE',
  UPDATE_CHART_TYPE: 'visualizer/UPDATE_CHART_TYPE',
  UPDATE_DRAWN_RECT: 'visualizer/UPDATE_DRAWN_RECT',
  UPDATE_ANALYSIS_RESULTS: 'visualizer/UPDATE_ANALYSIS_RESULTS',
  UPDATE_FILTERS: 'visualizer/UPDATE_FILTERS',
  UPDATE_QUERY_INFO: 'visualizer/UPDATE_QUERY_INFO',
  FETCH_INDEX_STATUS: 'visualizer/FETCH_INDEX_STATUS',
  UPDATE_CLUSTER_STATS: 'visualizer/UPDATE_CLUSTER_STATS',
  UPDATE_EXPANDED_CLUSTER_INDEX: 'visualizer/UPDATE_EXPANDED_CLUSTER_INDEX',
  FETCH_ROW: 'visualizer/FETCH_ROW',
  UPDATE_TIME_RANGE: 'visualizer/UPDATE_TIME_RANGE',
  UPDATE_FREQUENCY: 'visualizer/UPDATE_FREQUENCY',
  UPDATE_TIME_SERIES: 'visualizer/UPDATE_TIME_SERIES',
  UPDATE_OPEN_TIME_SERIES_MODAL: 'visualizer/UPDATE_OPEN_TIME_SERIES_MODAL',
  UPDATE_TIME_SERIES_MEASURE: 'visualizer/UPDATE_TIME_SERIES_MEASURE',
};

const initialState = {
  indexStatus: defaultValue,
  loading: true,
  errorMessage: null,
  dataset: null as IDataset,
  datasets: null as IDataset[],
  zoom: 14,
  chartType: 'column',
  categoricalFilters: {},
  groupByCols: null as string[],
  measureCol: null,
  aggType: AggregateFunctionType.COUNT,
  viewRect: null as IRectangle,
  drawnRect: null as IRectangle,
  series: [] as IGroupedStats[],
  facets: {},
  rectStats: null as IRectStats,
  clusters: [],
  fullyContainedTileCount: 0,
  tileCount: 0,
  pointCount: 0,
  ioCount: 0,
  totalTileCount: 0,
  totalPointCount: 0,
  executionTime: 0,
  totalTime: 0,
  bounds: null,
  expandedClusterIndex: null,
  row: null,
  selectedPointId: null,
  timeRange: {from: 0, to: Date.now()},
  frequency: 900,
  timeSeries: null as [],
  timeSeriesResultsLoading: null,
  openTimeSeriesModal: false,
  timeSeriesMeasureCol: null as string,
};

export type VisualizerState = Readonly<typeof initialState>;

// Reducer

export default (state: VisualizerState = initialState, action): VisualizerState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_DATASET):
      return {
        ...initialState,
        errorMessage: null,
        loading: true,
      };
    case FAILURE(ACTION_TYPES.FETCH_DATASET):
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.FETCH_DATASET): {
      const dataset = action.payload.data;
      return {
        ...state,
        loading: false,
        dataset,
        groupByCols: action.meta.groupByCols || [dataset.dimensions[0]],
        measureCol: action.meta.measureCol == null ? dataset.measure0 : action.meta.measureCol,
        categoricalFilters: action.meta.categoricalFilters || {},
        viewRect: action.meta.viewRect || {
          lat: [dataset.queryYMin, dataset.queryYMax],
          lon: [dataset.queryXMin, dataset.queryXMax],
        },
        chartType: action.meta.chartType || 'column',
        timeRange:{from: dataset.timeMin, to: Date.now()},
      };
    }
    case SUCCESS(ACTION_TYPES.FETCH_DATASET_LIST):
      return {
        ...state,
        datasets: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.UPDATE_CLUSTERS):
      return {
        ...state,
        clusters: action.payload,
        expandedClusterIndex: null,
        totalTime: new Date().getTime() - action.meta.requestTime,
      };
    case ACTION_TYPES.UPDATE_FACETS:
      return {
        ...state,
        facets: action.payload,
      };
    case ACTION_TYPES.UPDATE_GROUP_BY:
      return {
        ...state,
        groupByCols: action.payload,
        series: [],
      };
    case ACTION_TYPES.UPDATE_MEASURE:
      return {
        ...state,
        measureCol: action.payload,
      };
    case ACTION_TYPES.UPDATE_TIME_SERIES_MEASURE:
      return {
        ...state,
        timeSeriesMeasureCol: action.payload,
      };
    case ACTION_TYPES.UPDATE_FILTERS:
      return {
        ...state,
        categoricalFilters: action.payload,
      };
    case ACTION_TYPES.UPDATE_AGG_TYPE:
      return {
        ...state,
        aggType: action.payload,
      };
    case ACTION_TYPES.UPDATE_CHART_TYPE:
      return {
        ...state,
        chartType: action.payload,
      };
    case ACTION_TYPES.UPDATE_DRAWN_RECT:
      return {
        ...state,
        drawnRect: action.payload,
      };
    case ACTION_TYPES.UPDATE_MAP_BOUNDS:
      return {
        ...state,
        zoom: action.payload.zoom,
        viewRect: action.payload.viewRect,
      };
    case ACTION_TYPES.UPDATE_TIME_RANGE:
      return {
        ...state,
        timeRange: action.payload,
      };
    case ACTION_TYPES.UPDATE_FREQUENCY:
      return {
        ...state,
        frequency: action.payload,
      };
    case SUCCESS(ACTION_TYPES.UPDATE_ANALYSIS_RESULTS):
      return {
        ...state,
        series: action.payload.data.series,
        rectStats: action.payload.data.rectStats,
      };
    case ACTION_TYPES.UPDATE_ANALYSIS_RESULTS:
      return {
        ...state,
        series: action.payload.data.series,
        rectStats: action.payload.data.rectStats,
      };
    case ACTION_TYPES.UPDATE_QUERY_INFO:
      return {
        ...state,
        fullyContainedTileCount: action.payload.fullyContainedTileCount,
        tileCount: action.payload.tileCount,
        pointCount: action.payload.pointCount,
        ioCount: action.payload.ioCount,
        totalTileCount: action.payload.totalTileCount,
        totalPointCount: action.payload.totalPointCount,
        executionTime: action.payload.executionTime,
      };
    case SUCCESS(ACTION_TYPES.RESET):
      return {
        ...state,
        drawnRect: null,
        indexStatus: defaultValue,
      };
    case SUCCESS(ACTION_TYPES.FETCH_INDEX_STATUS):
      return {
        ...state,
        indexStatus: action.payload.data,
      };
    case ACTION_TYPES.UPDATE_EXPANDED_CLUSTER_INDEX:
      return {
        ...state,
        expandedClusterIndex: action.payload,
      };
    case REQUEST(ACTION_TYPES.FETCH_ROW):
      return {
        ...state,
        selectedPointId: action.meta,
        row: null,
      };
    case SUCCESS(ACTION_TYPES.FETCH_ROW):
      return {
        ...state,
        row: action.payload.data,
      };
    case REQUEST(ACTION_TYPES.UPDATE_TIME_SERIES):
      return {
        ...state,
        timeSeriesResultsLoading: true,
      };
    case SUCCESS(ACTION_TYPES.UPDATE_TIME_SERIES):
      return {
        ...state,
        timeSeries: action.payload.data,
        timeSeriesResultsLoading: false,
      };
    case ACTION_TYPES.UPDATE_OPEN_TIME_SERIES_MODAL:
      return {
        ...state,
         openTimeSeriesModal: action.payload,
      };
    default:
      return state;
  }
};
const parseRouteVisOptions = (query: string) => {
  const { v: viewRect, g, a: aggType, f: categoricalFilters, m, c: chartType } = qs.parse(query, { ignoreQueryPrefix: true });
  const measureCol = m && parseInt(m as string, 10);
  const groupByCols = g && (g as string[]).map(col => parseInt(col, 10));
  return { viewRect, groupByCols, aggType, categoricalFilters, measureCol, chartType };
};

export const urlEncodeVisOptions = options => {
  const { viewRect, measureCol, aggType, categoricalFilters, groupByCols, chartType } = options;
  return qs.stringify(
    {
      v: viewRect,
      g: groupByCols,
      a: aggType,
      f: categoricalFilters,
      m: measureCol,
      c: chartType,
    },
    { skipNulls: true }
  );
};

export const getVisURL = options => {
  const { dataset } = options;
  return window.location.protocol + '//' + window.location.host + '/visualize/' + dataset.id;
};

// Actions
export const getDataset = (id, urlQueryString) => {
  const requestUrl = `api/datasets/${id}`;
  return {
    type: ACTION_TYPES.FETCH_DATASET,
    payload: axios.get<IDataset>(requestUrl),
    meta: parseRouteVisOptions(urlQueryString),
  };
};

export const getDatasets = () => {
  const requestUrl = `api/datasets/`;
  return {
    type: ACTION_TYPES.FETCH_DATASET_LIST,
    payload: axios.get<IDataset>(requestUrl),
  };
};

export const getRow = (datasetId, rowId) => {
  const requestUrl = `api/datasets/${datasetId}/objects/${rowId}`;
  return {
    type: ACTION_TYPES.FETCH_ROW,
    payload: axios.get(requestUrl),
    meta: rowId,
  };
};

const prepareSupercluster = (points, measure0, measure1, dimensions) => {
  const geoJsonPoints = points.map(point => {
    const geoJsonPoint = {
      type: 'Feature',
      properties: { totalCount: point[2], points: [point]},
      geometry: {
        type: 'Point',
        coordinates: [point[1], point[0]],
      },
    };
    geoJsonPoint.properties[measure0] = point[4];
    geoJsonPoint.properties[measure1] = point[5];

    dimensions.forEach((dim, index) => {
      geoJsonPoint.properties[dim] = point[6][index];
    });

    return geoJsonPoint;
  });

  

  const supercluster = new Supercluster({
    log: false,
    radius: 60,
    extent: 256,
    maxZoom: MAX_ZOOM,
    minPoints: 3,
    reduce(accumulated, props) {
      accumulated.totalCount += props.totalCount;
      accumulated.points = accumulated.points.concat(props.points);
      
      // dimensions.forEach((dim, index) => {
      //   if (accumulated[dim] != null && props[dim] != null && accumulated[dim] !== props[dim]) {
      //     accumulated[dim] = "Multiple";
      //   } else if (!accumulated[dim]) {
      //     accumulated[dim] = props[dim];
      //   }
      // });

      dimensions.forEach((dim, index) => {
        let accumValues = accumulated[dim] || [];
        let propValues = props[dim] || [];
        // Ensure both are arrays
        if (!Array.isArray(accumValues)) {
          accumValues = [accumValues];
        }
        if (!Array.isArray(propValues)) {
          propValues = [propValues];
        }
        // Combine and deduplicate
        const uniqueValues = new Set([...accumValues, ...propValues]);
        accumulated[dim] = Array.from(uniqueValues);
      });

      // Calculate average for measure0 (point[4])
      const measure0Values = accumulated.points.map(point => point[4]).filter(val => val !== null);
      accumulated[measure0] = measure0Values.length > 0 ? measure0Values.reduce((acc, val) => acc + val, 0) / measure0Values.length : null;

      // Calculate average for measure1 (point[5])
      const measure1Values = accumulated.points.map(point => point[5]).filter(val => val !== null);
      accumulated[measure1] = measure1Values.length > 0 ? measure1Values.reduce((acc, val) => acc + val, 0) / measure1Values.length : null;    },
  });
  supercluster.load(geoJsonPoints);
  return supercluster;
};


const updateAnalysisResults = id => (dispatch, getState) => {
  const { dataset, categoricalFilters, drawnRect, groupByCols, measureCol, aggType, chartType, viewRect } = getState().visualizer;
  const analysisQuery = { categoricalFilters, rect: drawnRect || viewRect, groupByCols, measureCol, aggType } as IQuery;
  history.pushState(null, null, getVisURL(getState().visualizer));
  dispatch({
    type: ACTION_TYPES.UPDATE_ANALYSIS_RESULTS,
    payload: axios.post(`api/datasets/${id}/query`, analysisQuery),
  });
};


export const updateClusters = id => (dispatch, getState) => {
  const {
    categoricalFilters,
    viewRect,
    zoom,
    groupByCols,
    measureCol,
    aggType,
    chartType,
    drawnRect,
    dataset,
    timeRange
  } = getState().visualizer;
  const requestTime = new Date().getTime();
  if (viewRect == null) {
    return;
  }
  history.pushState(null, null, getVisURL(getState().visualizer));
  dispatch({
    type: ACTION_TYPES.UPDATE_CLUSTERS,
    meta: { requestTime },
    payload: axios
      .post(`api/datasets/${id}/query`, {
        rect: viewRect,
        zoom,
        categoricalFilters,
        groupByCols,
        measureCol,
        aggType,
        from: timeRange.from,
        to: timeRange.to
      })
      .then(res => {
        dispatch({ type: ACTION_TYPES.UPDATE_FACETS, payload: res.data.facets });
        const responseTime = new Date().getTime();
        dispatch({
          type: ACTION_TYPES.UPDATE_QUERY_INFO,
          payload: { ...res.data, executionTime: responseTime - requestTime },
        });
        if (drawnRect == null) {
          dispatch({
            type: ACTION_TYPES.UPDATE_ANALYSIS_RESULTS,
            payload: res,
          });
        }
        let points = res.data.points || [];
        const supercluster = prepareSupercluster(points, dataset.measure0, dataset.measure1, dataset.dimensions);
        return supercluster.getClusters([-180, -85, 180, 85], zoom);
      }),
  });
};

export const updateFilters = (id, filters) => dispatch => {
  dispatch({
    type: ACTION_TYPES.UPDATE_FILTERS,
    payload: filters,
  });
  dispatch(updateAnalysisResults(id));
  dispatch(updateClusters(id));
};

export const updateGroupBy = (id, groupByCols) => (dispatch, getState) => {
  const { categoricalFilters } = getState().visualizer;

  dispatch({
    type: ACTION_TYPES.UPDATE_GROUP_BY,
    payload: groupByCols,
  });
  const newCategoricalFilters = { ...categoricalFilters };
  groupByCols.forEach(groupByCol => {
    delete newCategoricalFilters[groupByCol];
  });
  dispatch(updateFilters(id, newCategoricalFilters));
};

export const updateMeasure = (id, measureCol) => dispatch => {
  dispatch({
    type: ACTION_TYPES.UPDATE_MEASURE,
    payload: measureCol,
  });
  dispatch(updateAnalysisResults(id));
};

export const updateChartType = (chartType: string) => (dispatch, getState) => {
  dispatch({
    type: ACTION_TYPES.UPDATE_CHART_TYPE,
    payload: chartType,
  });
  history.pushState(null, null, getVisURL(getState().visualizer));
};

export const updateAggType = (id, aggType) => dispatch => {
  dispatch({
    type: ACTION_TYPES.UPDATE_AGG_TYPE,
    payload: aggType,
  });
  dispatch(updateAnalysisResults(id));
};

export const updateDrawnRect = (id, drawnRectBounds: LatLngBounds) => dispatch => {
  const drawnRect = drawnRectBounds && {
    lat: [drawnRectBounds.getSouth(), drawnRectBounds.getNorth()],
    lon: [drawnRectBounds.getWest(), drawnRectBounds.getEast()],
  };
  dispatch({
    type: ACTION_TYPES.UPDATE_DRAWN_RECT,
    payload: drawnRect,
  });
  dispatch(updateAnalysisResults(id));
  dispatch(updateTimeSeries(id));
};

export const updateMapBounds = (id, bounds: LatLngBounds, zoom: number) => dispatch => {
  const viewRect = {
    lat: [bounds.getSouth(), bounds.getNorth()],
    lon: [bounds.getWest(), bounds.getEast()],
  };
  dispatch({
    type: ACTION_TYPES.UPDATE_MAP_BOUNDS,
    payload: { zoom, viewRect },
  });
  dispatch(updateClusters(id));
};

export const reset = id => async dispatch => {
  const requestUrl = `api/datasets/${id}/reset-index`;
  await dispatch({
    type: ACTION_TYPES.RESET,
    payload: axios.post(requestUrl),
  });
  dispatch(updateClusters(id));
};

export const getIndexStatus = id => {
  const requestUrl = `api/datasets/${id}/status`;
  return {
    type: ACTION_TYPES.FETCH_INDEX_STATUS,
    payload: axios.get<IIndexStatus>(requestUrl),
  };
};

export const updateTimeRange = (id, timeRange) => dispatch => {
  dispatch({
    type: ACTION_TYPES.UPDATE_TIME_RANGE,
    payload: timeRange,
  });
  dispatch(updateAnalysisResults(id));
  dispatch(updateClusters(id));
  dispatch(updateTimeSeries(id));
};

export const updateFrequency = (id, frequency) => dispatch => {
  dispatch({
    type: ACTION_TYPES.UPDATE_FREQUENCY,
    payload: frequency,
  });
  dispatch(updateTimeSeries(id));
};

export const updateTimeSeries = (id) => (dispatch, getState) => {
  const timeSeriesQuery = {
      from: getState().visualizer.timeRange.from,
      to: getState().visualizer.timeRange.to,
      measure: getState().visualizer.timeSeriesMeasureCol,
      frequency: getState().visualizer.frequency, 
      rectangle: getState().visualizer.drawnRect,
      categoricalFilters: getState().visualizer.categoricalFilters,
  }
  dispatch({
    type: ACTION_TYPES.UPDATE_TIME_SERIES,
    payload: axios.post(`api/datasets/${id}/timeseries`, timeSeriesQuery),
  });
}

export const updateOpenTimeSeriesModal = (openTimeSeriesModal) => (dispatch)  => {
  dispatch({
    type: ACTION_TYPES.UPDATE_OPEN_TIME_SERIES_MODAL,
    payload: openTimeSeriesModal,
  });
}

export const updateTimeSeriesMeasure = (id, timeSeriesMeasureCol) => dispatch => {
  dispatch({
    type: ACTION_TYPES.UPDATE_TIME_SERIES_MEASURE,
    payload: timeSeriesMeasureCol,
  });
  dispatch(updateTimeSeries(id));
};
