import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import ForecastingDataPrep from '../forecasting-data-preparation/forecasting-dataPrep';
import ForecastingFeatureExtr from '../forecasting-feature-extraction/forecasting-feature-extraction';
import ForecastingAlgSelection from '../forecasting-train-results/forecasting-alg-selection';
import ForecastingResults from '../forecasting-results/forecasting-results';
import { IDataset } from 'app/shared/model/dataset.model';
import { Step, Button } from 'semantic-ui-react';
import { IForecastingDefault, IForecastingForm } from 'app/shared/model/forecasting.model';
import { ITimeRange } from 'app/shared/model/time-range.model';
import { IForecastingResults } from 'app/shared/model/forecasting-results.model';
import { saveModel, startTraining, toggleTrainStepper, updatePredictions } from '../forecasting.reducer';
import {updateFrequency} from '../../visualizer/visualizer.reducer';
import { IUnivariateDataPoint } from 'app/shared/model/univariate-datapoint.model';
const steps = ['Data Selection', 'Feature Selection', 'Algorithm Selection'];

export interface IForecastingTrainStepperProps {
  dataset: IDataset,
  timeRange: ITimeRange,
  timeSeries: IUnivariateDataPoint[],
  timeSeriesMeasureCol: string,
  frequency: number,
  setNewTrain: any,
  forecastingResults: IForecastingResults,
  savedModels: any,
  forecastingForm: IForecastingForm,
  updateFrequency: typeof updateFrequency,
  startTraining: typeof startTraining,
  saveModel: typeof saveModel,
  updatePredictions: typeof updatePredictions,
  setForecastingForm: Dispatch<SetStateAction<IForecastingForm>>,
  toggleTrainStepper: typeof toggleTrainStepper,
}
const ForecastingTrainStepper = (props: IForecastingTrainStepperProps) => {
  const { dataset, timeSeries, timeSeriesMeasureCol, frequency, setNewTrain, timeRange, forecastingResults, savedModels, forecastingForm } = props;
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());

  const getTimeIntervalFromFrequency = () => {
    switch(frequency){
      case 900:
        return '15m';
      case 1800:
        return '30m';
      case 3600:
        return '1h';
      case 21600:
        return '6h';
      default:
        return '15m';
    }
  }

  useEffect(() => {
    props.toggleTrainStepper(true);
    dataset && props.setForecastingForm(state => ({
      ...state,
      kind: dataset.id,
      targetColumn: timeSeriesMeasureCol,
      startDate: timeRange.from,
      endDate: timeRange.to,
      time_interval: getTimeIntervalFromFrequency(),
      features: {
        ...state.features,
        columnFeatures: state.features.columnFeatures.filter(f => f === timeSeriesMeasureCol),
      },
    }));
  }, []);

  const handleTrain = e => {
    props.toggleTrainStepper(false);
    props.startTraining(forecastingForm);
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };
  
  const isStepOptional = step => {
    return step === 1;
  };

  const isStepSkipped = step => {
    return skipped.has(step);
  };

  const handleNextButton = () => {
    if (activeStep === 0) {
      if (forecastingForm.startDate !== null && forecastingForm.endDate !== null) {
        return false;
      } else {
        return true;
      }
    } else if (activeStep === 1) {
      if (forecastingForm.features.columnFeatures.length !== 0) {
        return false;
      } else {
        return true;
      }
    } else if (activeStep === 2) {
      if (Object.keys(forecastingForm.algorithms).length !== 0 && forecastingForm.future_predictions) {
        return false;
      } else {
        return true;
      }
    }
    return false;
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep(prevActiveStep => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }
    setActiveStep(prevActiveStep => prevActiveStep + 1);
    setSkipped(prevSkipped => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setNewTrain(false);
    setActiveStep(0);
    props.toggleTrainStepper(false);
    props.updatePredictions([]);
  };

  return (
    <>
      { (activeStep !== 3) && <div style={{ width: '100%', height: '15%', border: 'none'  }}>
        <Step.Group fluid>
          {steps.map((label, index) => (
            <Step key={label} active={activeStep === index}>
              <Step.Content>
                <Step.Title>{label}</Step.Title>
              </Step.Content>
            </Step>
          ))}
        </Step.Group>
      </div>
    }
    <div style={{ width: '100%', height: '70%',  display: 'flex', border: 'none' }}>
      {activeStep === 0 ? (
        <ForecastingDataPrep dataset={dataset} forecastingForm={forecastingForm} setForecastingForm={props.setForecastingForm} updateFrequency={props.updateFrequency}/>
      ) : activeStep === 1 ? (
        <ForecastingFeatureExtr dataset={dataset} timeSeriesMeasureCol={timeSeriesMeasureCol} forecastingForm={forecastingForm} setForecastingForm={props.setForecastingForm} />
      ) : activeStep === 2 ? (
        <ForecastingAlgSelection forecastingForm={forecastingForm} setForecastingForm={props.setForecastingForm} />
      ) : (
        <ForecastingResults 
        dataset={dataset} timeSeries={timeSeries} 
        timeSeriesMeasureCol={timeSeriesMeasureCol} 
        forecastingForm={forecastingForm} savedModels={savedModels}
        forecastingResults={forecastingResults} updatePredictions = {props.updatePredictions} saveModel={props.saveModel} />
      )}
    </div>
    <div style={{ textAlign:'right', width:'100%', height: '15%', margin:'1em', paddingRight: '3em' }}>
      {activeStep === 3 ? (
        <Button size="small" color="grey" style={{ fontSize: '12px', height: '90%' }} onClick={handleReset}>
          Reset
        </Button>
      ) : (
        <>
          <Button size="small" color="grey" disabled={activeStep === 0} onClick={handleBack} style={{ fontSize: '12px', height: '90%' }}>
            Back
          </Button>
          <Button
            size="small"
            style={{ fontSize: '12px', height: '90%' }}
            onClick={activeStep === steps.length - 1 ? handleTrain : handleNext}
            disabled={handleNextButton()}
          >
            {activeStep === steps.length - 1 ? 'Train' : 'Next'}
          </Button>
        </>
      )}
    </div>
    </>
  );
};

export default ForecastingTrainStepper;
