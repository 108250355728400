import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import {
  IForecastingForm,
  ILGBMDefault,
  ILGBMDefaultFT,
  ILGBMIntervals,
  IStringParameters,
  IXGBoostDefault,
  IXGBoostDefaultFT,
  IXGBoostIntervals,
} from 'app/shared/model/forecasting.model';
import { Button, Checkbox, Form, Header, Input, Modal, Segment } from 'semantic-ui-react';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '60%',
  height: '60%',
  bgcolor: 'background.paper',
  boxShadow: 24,
};

interface IForecastingAlgModal {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  forecastingForm: IForecastingForm;
  setForecastingForm: Dispatch<SetStateAction<IForecastingForm>>;
  setFineTuneSelection: Dispatch<SetStateAction<string[]>>;
  selectedAlgo: string;
  fineTuneSelection: string[];
}

const ForecastingAlgModal = (props: IForecastingAlgModal) => {
  const { open, setOpen, forecastingForm, setForecastingForm, selectedAlgo, fineTuneSelection, setFineTuneSelection } = props;
  const [algoParameters, setAlgoParameters] = useState(null);
  useEffect(() => {
    if (selectedAlgo === 'XGBoost') {
      setAlgoParameters(IXGBoostIntervals);
    } else if (selectedAlgo === 'LGBM') {
      setAlgoParameters(ILGBMIntervals);
    } else if (selectedAlgo === 'LinearRegression') {
    }
    // setAlgoParameters()
  }, [selectedAlgo]);

  const handleFineTuneCheckbox = algName => (e, data) => {
    const tempForm = {...forecastingForm};
    if (data.checked) {
      setFineTuneSelection(state => [...state, algName]);
    } else {
      setFineTuneSelection(fineTuneSelection.filter(n => n !== algName));
    }
    if (algName === 'XGBoost') {
      if (data.checked) {
        setForecastingForm(state => ({ ...state, algorithms: { ...state.algorithms, XGBoost: IXGBoostDefaultFT } }));
      } else {
        delete tempForm.algorithms['XGBoost'];
        setForecastingForm(state => ({ ...state, algorithms: { ...state.algorithms, XGBoost: IXGBoostDefault } }));
      }
    } else if (algName === 'LGBM') {
      if (data.checked) {
        setForecastingForm(state => ({ ...state, algorithms: { ...state.algorithms, LGBM: ILGBMDefaultFT } }));
      } else {
        delete tempForm.algorithms['LGBM'];
        setForecastingForm(state => ({ ...state, algorithms: { ...state.algorithms, LGBM: ILGBMDefault } }));
      }
    }
  };
  
  const prettyPrintJSON = obj => {
    return JSON.stringify(
      obj,
      function (key, value) {
        if (Array.isArray(value)) {
          return (
            '[' +
            value
              .map(function (item) {
                return JSON.stringify(item);
              })
              .join(', ') +
            ']'
          );
        }
        return value;
      },
      2
    );
  };
  
  const handleFTNumericBoxChange = (parameter, idx) => (e, data) => {
    const arrayCopy = [...forecastingForm.algorithms[selectedAlgo][parameter]];
    arrayCopy[idx] = parseFloat(data.value);
    setForecastingForm(state => ({
      ...state,
      algorithms: { ...state.algorithms, [selectedAlgo]: { ...state.algorithms[selectedAlgo], [parameter]: arrayCopy } },
    }));
  };
  
  const handleNumericBoxChange = parameter => (e, data) => {
    setForecastingForm(state => ({
      ...state,
      algorithms: { ...state.algorithms, [selectedAlgo]: { ...state.algorithms[selectedAlgo], [parameter]: parseFloat(data.value) } },
    }));
  };
  
  const handleStringBoxChange = parameter => (e, data) => {
    setForecastingForm(state => ({
      ...state,
      algorithms: { ...state.algorithms, [selectedAlgo]: { ...state.algorithms[selectedAlgo], [parameter]: data.value } },
    }));
  };
  
  const handleClose = () => {
    setOpen(!open);
  };
  

  return (
    <>
      <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" hideBackdrop>
          <Segment.Group>
            <Segment style={{ height: '6%', backgroundColor: '#f0f0f0', display: 'flex', alignItems: 'center' }}>
              <Header as="h2" style={{ flex: 1, paddingLeft: '1rem', color: '#666666' }}>
                Configuration
              </Header>
              <Button icon="close" onClick={handleClose} style={{ borderRadius: 0, ':hover': { color: '#ff6666' } }} />
            </Segment>
            <Segment.Group horizontal style={{ height: '94%' }}>
              <Segment style={{ width: '50%', height: '100%', overflowY: 'scroll', display: 'flex', flexDirection: 'column', borderRight: '1px solid rgba(0,0,0,0.4)', paddingRight: '0.5rem', paddingLeft: '0.5rem' }}>
                <Form.Field>
                  <Checkbox label="Fine Tune" onChange={handleFineTuneCheckbox(selectedAlgo)} />
                </Form.Field>
                {Object.keys(forecastingForm.algorithms).includes(selectedAlgo) &&
                  Object.keys(forecastingForm.algorithms[selectedAlgo]).map(par => (
                    <div key={`algoParameter-${par}`}>
                      <Header as="h4" style={{ marginTop: '0.5rem', fontWeight: 500, backgroundColor: '#f0f0f0', paddingLeft: '1rem', color: '#333333' }}>
                        {par}
                      </Header>
                      <div style={{ width: '100%', display: 'flex', columnGap: '0.5rem', padding: '10px', backgroundColor: '#f9f9f9' }}>
                        {typeof forecastingForm.algorithms[selectedAlgo][par] !== 'string' ? (
                          fineTuneSelection.includes(selectedAlgo) ? (
                            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                              <Input label="Min" type="number" value={forecastingForm.algorithms[selectedAlgo][par][0]} onChange={handleFTNumericBoxChange(par, 0)} style={{ width: '20%' }} />
                              <Input label="Max" type="number" value={forecastingForm.algorithms[selectedAlgo][par][1]} onChange={handleFTNumericBoxChange(par, 1)} style={{ width: '20%' }} />
                              <Input label="Step" type="number" value={forecastingForm.algorithms[selectedAlgo][par][2]} onChange={handleFTNumericBoxChange(par, 2)} style={{ width: '20%' }} />
                            </div>
                          ) : (
                            <Input label="Value" type="number" value={forecastingForm.algorithms[selectedAlgo][par]} onChange={handleNumericBoxChange(par)} style={{ width: '20%' }} />
                          )
                        ) : (
                          <Form.Select fluid label={par} options={IStringParameters[par].map(val => ({ key: val, text: val, value: val }))} value={forecastingForm.algorithms[selectedAlgo][par]} onChange={handleStringBoxChange(par)} />
                        )}
                      </div>
                    </div>
                  ))}
              </Segment>
              <Segment style={{ width: '50%', display: 'flex', flexDirection: 'column', justifyContent: 'center', textAlign: 'center', overflowY: 'auto', gap: '2rem' }}>
                <Header as="h2" style={{ marginTop: '0.5rem', color: '#666666', fontWeight: 800, fontSize: '1.5rem' }}>
                  {`${selectedAlgo} parameters`}
                </Header>
                <pre style={{ marginTop: '0.5rem', color: '#2185d0', fontWeight: 400, fontSize: '1.2rem', lineHeight: 1.4 }}>
                  {forecastingForm.algorithms[selectedAlgo] && prettyPrintJSON(forecastingForm.algorithms[selectedAlgo])}
                </pre>
              </Segment>
            </Segment.Group>
          </Segment.Group>
        </Modal>
    </>
  );
};

export default ForecastingAlgModal;
