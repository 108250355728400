import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import Chart from 'app/modules/visualizer/chart';
import Map from 'app/modules/visualizer/map';
import StatsPanel from 'app/modules/visualizer/stats-panel';
import VisControl from 'app/modules/visualizer/vis-control';
import { IRootState } from 'app/shared/reducers';
import { Header, Modal, Progress } from 'semantic-ui-react';
import {
  getDataset,
  getDatasets,
  getIndexStatus,
  getRow, getVisURL,
  reset,
  updateAggType,
  updateChartType,
  updateClusters,
  updateDrawnRect,
  updateFilters,
  updateGroupBy,
  updateMapBounds,
  updateMeasure,
  updateTimeRange,
  updateTimeSeries,
  updateOpenTimeSeriesModal,
  updateTimeSeriesMeasure,
  updateFrequency,
} from './visualizer.reducer';
import {
  getSavedModels,
  saveModel,
  startTraining,
  updatePredictions,
  toggleTrainStepper,
} from '../forecasting/forecasting.reducer';

import './visualizer.scss';
import { TimeSeriesChart } from './ts-chart';
import { times } from 'lodash';
import { TimeSeriesVisualizer } from './ts-visualizer';
import Forecasting from '../forecasting/forecasting';
import { IForecastingDefault, IForecastingForm } from 'app/shared/model/forecasting.model';

export interface IVisPageProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {
}

export const VisPage = (props: IVisPageProps) => {
  const {
    dataset,
    datasets,
    loading,
    indexStatus,
    clusters,
    viewRect,
    series,
    rectStats,
    groupByCols,
    aggType,
    chartType,
    measureCol,
    categoricalFilters,
    facets, ioCount, pointCount, tileCount, fullyContainedTileCount,
    totalPointCount, zoom, totalTileCount, totalTime, executionTime,
    row, drawnRect, timeSeries, timeSeriesResultsLoading, openTimeSeriesModal,
    timeSeriesMeasureCol, savedModels, timeRange, frequency, forecastingResults,
    predictions, isInTrainStepper,
  } = props;

  useEffect(() => {
    props.getDataset(props.match.params.id, props.location.search);
    props.getDatasets();
    props.getIndexStatus(props.match.params.id);
  }, [props.match.params.id]);

  useEffect(() => {
    if (!indexStatus.isInitialized) {
      setTimeout(() => {
        props.getIndexStatus(props.match.params.id);
      }, 1000);
    } /* else if (viewRect) {
      props.updateClusters(props.match.params.id);
    }*/
  }, [indexStatus]);

  const copyCurrentURL = () => {
    const textArea = document.createElement('textarea');
    textArea.value = getVisURL({
      dataset, viewRect, measureCol, aggType, categoricalFilters, groupByCols, chartType
    });
    document.body.appendChild(textArea);
    textArea.select();
    const successful = document.execCommand('copy');
    document.body.removeChild(textArea);
  };

  const [forecastingForm, setForecastingForm] = useState<IForecastingForm>(IForecastingDefault);

  
  return !loading && <div>
    <VisControl dataset={dataset} datasets={datasets} groupByCols={groupByCols} categoricalFilters={categoricalFilters}
                facets={facets}
                updateFilters={props.updateFilters} reset={props.reset} 
                copyCurrentURL={copyCurrentURL}
                updateTimeRange={props.updateTimeRange}
                />
    <Map id={props.match.params.id} clusters={clusters} updateMapBounds={props.updateMapBounds}
         updateDrawnRect={props.updateDrawnRect} dataset={dataset}
         viewRect={viewRect} zoom={zoom} 
         row={row} getRow={props.getRow}
         expandedClusterIndex={props.expandedClusterIndex}
        />
    <div className='left-panel-group'>
      {rectStats && 
        <>
          {(dataset.measure0 != null) &&
          <StatsPanel dataset={dataset} rectStats={rectStats} pointCount={pointCount}/>}
        </>
      }
    </div>
    <div className='right-panel-group'>
      {rectStats  && (dataset.measure0 != null) &&
        <>
          <Chart dataset={dataset} series={series} updateGroupBy={props.updateGroupBy}
                groupByCols={groupByCols}
                aggType={aggType} chartType={chartType} updateChartType={props.updateChartType} measureCol={measureCol}
                updateAggType={props.updateAggType}
                updateMeasure={props.updateMeasure}/>
          {drawnRect && <TimeSeriesChart 
            dataset={dataset}
            timeSeriesMeasureCol={timeSeriesMeasureCol} updateTimeSeriesMeasure={props.updateTimeSeriesMeasure}
            updateFrequency={props.updateFrequency}
            timeSeries={timeSeries} timeSeriesResultsLoading = {timeSeriesResultsLoading} frequency={frequency}
            updateTimeSeries={props.updateTimeSeries} updateOpenTimeSeriesModal={props.updateOpenTimeSeriesModal}/>
          }
        </>
      }
    </div>
    <Modal
      basic
      open={!indexStatus.isInitialized}
      size='small'>
      <Header textAlign='center'>
        Parsing and indexing dataset {dataset.name}
      </Header>
      <Modal.Content>
        <Progress inverted value={indexStatus.objectsIndexed} total={dataset.objectCount}
                  label={"Objects indexed: " + indexStatus.objectsIndexed} autoSuccess/>
      </Modal.Content>
    </Modal>
    <Modal open={openTimeSeriesModal} onClose={() => {props.toggleTrainStepper(false);props.updateOpenTimeSeriesModal(false)}}>
      <Modal.Header>Forecasting {timeSeriesMeasureCol} </Modal.Header>
      <Modal.Content>
        <TimeSeriesVisualizer 
          timeSeries={timeSeries}
          predictions={predictions}
          timeSeriesMeasureCol={timeSeriesMeasureCol}
          updateTimeSeries={props.updateTimeSeries} 
          updateOpenTimeSeriesModal={props.updateOpenTimeSeriesModal} 
          isInTrainStepper={isInTrainStepper}
          forecastingForm={forecastingForm}/>
        <Forecasting dataset={dataset} timeSeries={timeSeries} timeRange={timeRange} frequency={frequency} timeSeriesMeasureCol={timeSeriesMeasureCol}
         forecastingResults={forecastingResults} savedModels={savedModels} getSavedModels={props.getSavedModels} updatePredictions={props.updatePredictions}
         updateFrequency ={props.updateFrequency} startTraining={props.startTraining} saveModel={props.saveModel}
         forecastingForm={forecastingForm} setForecastingForm={setForecastingForm} toggleTrainStepper={props.toggleTrainStepper}/>
      </Modal.Content>
    </Modal>
  </div>
};

const mapStateToProps = ({visualizer, forecasting}: IRootState, ownProps) => ({
  loading: visualizer.loading,
  dataset: visualizer.dataset,
  datasets: visualizer.datasets,
  viewRect: visualizer.viewRect,
  drawnRect: visualizer.drawnRect,
  series: visualizer.series,
  rectStats: visualizer.rectStats,
  clusters: visualizer.clusters,
  groupByCols: visualizer.groupByCols,
  chartType: visualizer.chartType,
  aggType: visualizer.aggType,
  measureCol: visualizer.measureCol,
  zoom: visualizer.zoom,
  categoricalFilters: visualizer.categoricalFilters,
  facets: visualizer.facets,
  indexStatus: visualizer.indexStatus,
  fullyContainedTileCount: visualizer.fullyContainedTileCount,
  tileCount: visualizer.tileCount,
  pointCount: visualizer.pointCount,
  ioCount: visualizer.ioCount,
  totalTileCount: visualizer.totalTileCount,
  totalPointCount: visualizer.totalPointCount,
  totalTime: visualizer.totalTime,
  executionTime: visualizer.executionTime,
  row: visualizer.row,
  expandedClusterIndex: visualizer.expandedClusterIndex,
  timeRange: visualizer.timeRange,
  frequency: visualizer.frequency,
  timeSeries: visualizer.timeSeries,
  timeSeriesResultsLoading: visualizer.timeSeriesResultsLoading,
  openTimeSeriesModal: visualizer.openTimeSeriesModal,
  timeSeriesMeasureCol: visualizer.timeSeriesMeasureCol,
  savedModels: forecasting.savedModels,
  forecastingResults: forecasting.forecastingResults,
  predictions: forecasting.predictions,
  isInTrainStepper: forecasting.isInTrainStepper,
});

const mapDispatchToProps = {
  getDataset,
  getDatasets,
  updateMapBounds,
  updateAggType,
  updateChartType,
  updateDrawnRect,
  updateGroupBy,
  updateMeasure,
  updateFilters,
  reset,
  getIndexStatus,
  updateClusters,
  getRow,
  updateTimeRange,
  updateFrequency,
  updateTimeSeries,
  updateOpenTimeSeriesModal,
  updateTimeSeriesMeasure,
  getSavedModels,
  saveModel,
  startTraining,
  updatePredictions,
  toggleTrainStepper,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(VisPage);
