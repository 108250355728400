import React, { useEffect } from 'react';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import HighchartsMore from 'highcharts/highcharts-more';
import { IDataset } from 'app/shared/model/dataset.model';
import { IUnivariateDataPoint } from 'app/shared/model/univariate-datapoint.model';
import { IForecastingResults } from 'app/shared/model/forecasting-results.model';
import { Modal, Segment } from 'semantic-ui-react';

HighchartsMore(Highcharts);

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '60%',
  height: 'max-content',
  bgcolor: 'background.paper',
  boxShadow: 24,
};

export interface IModalWithChartProps {
    dataset: IDataset,
    timeSeries: IUnivariateDataPoint[],
    resultsModal: any,
    setResultsModal: any,
    forecastingResults: IForecastingResults
}
const ModalWithChart = (props:IModalWithChartProps) => {
  const { dataset, timeSeries,  resultsModal, setResultsModal, forecastingResults } = props;

  const handleClose = () => {
    setResultsModal(null);
  };

  const generateCharts = () => {
    return [
        ...(Object.keys(forecastingResults.metrics).map(key => ({
            name: key,
            opposite: false,
            data: forecastingResults.metrics[key].predictions.map((point) => [point.timestamp, point.value]),
            offset: 0,
          }))),
      {
        name: 'initial Series',
        opposite: false,
        offset: 0,
        data: timeSeries.map(datapoint => [
            datapoint.timestamp,
            datapoint.value
        ]),
      },
    ];
  };

  const options = {
    title: {
      text: 'Initial vs. Predicted Time Series',
    },
    series: forecastingResults && timeSeries ? generateCharts() : null,
    xAxis: {
      ordinal: false,
      type: 'datetime',
    },
    plotOptions: {
      series: {
          dataGrouping: {
              enabled: true,
              forced: true,
              units: [
               ['minute', [15]]
            ]
          },
      }
    },
    rangeSelector: {
      enabled: false,
    },
    legend: {
      enabled: true,
    },
    credits: {
      enabled: false,
    },
  };

  return (
    <>
      <Modal open={resultsModal !== null} onClose={handleClose}>
        <Segment sx={style}>
          <HighchartsReact highcharts={Highcharts} constructorType={'stockChart'} options={options} />
        </Segment>
      </Modal>
    </>
  );
};

export default ModalWithChart;
