import React, {useEffect, useRef, useState} from 'react';
import Highcharts from "highcharts/highcharts";
import HighchartsReact from 'highcharts-react-official';
import HighchartsMore from 'highcharts/highcharts-more';
import { updateTimeSeries, updateOpenTimeSeriesModal } from './visualizer.reducer';
import { Segment } from 'semantic-ui-react';
import { IUnivariateDataPoint } from 'app/shared/model/univariate-datapoint.model';
import { IForecastingForm } from 'app/shared/model/forecasting.model';
HighchartsMore(Highcharts);

export interface ITSVisualizerProps {
    timeSeries: IUnivariateDataPoint[],
    timeSeriesMeasureCol: string,
    predictions: IUnivariateDataPoint[],
    forecastingForm: IForecastingForm,
    isInTrainStepper: boolean,
    updateTimeSeries: typeof updateTimeSeries,
    updateOpenTimeSeriesModal: typeof updateOpenTimeSeriesModal,
}

export const TimeSeriesVisualizer = (props: ITSVisualizerProps) => {
    const {timeSeries, predictions, timeSeriesMeasureCol, isInTrainStepper, forecastingForm} = props;
    const [seriesData, setSeriesData] = useState([]);
    const [predictionSeriesData, setPredictionSeriesData] = useState([]);
    
    const getZones = () => {
        const forecastingStartDate = timeSeries[0].timestamp;
        const forecastingEndDate = timeSeries[timeSeries.length - 1].timestamp;
        const forecastingDataSplit = forecastingForm.dataSplit;
        if(isInTrainStepper)
            return [
                { value: forecastingStartDate + (forecastingEndDate - forecastingStartDate) * (forecastingDataSplit[0] / 100), color:'#4CAF50'  },
                {
                value:
                    forecastingStartDate +
                    (forecastingEndDate - forecastingStartDate) * ((forecastingDataSplit[0] + forecastingDataSplit[1]) / 100),
                color: '#FF6B6B',
                },
                { value: forecastingEndDate, color: '#536DFE' },
            ];
        else return null;
    }

    useEffect(() => {
        if (timeSeries) {
            const formattedData = timeSeries.map(datapoint => [
                datapoint.timestamp,
                datapoint.value
            ]);
            setSeriesData(formattedData);
        }
    }, [timeSeries]);

    useEffect(() => {
        if (predictions) {
          const formattedPredictions = predictions.map(datapoint => [
            datapoint.timestamp,
            datapoint.value,
          ]);
          setPredictionSeriesData(formattedPredictions);
        }
      }, [predictions]);

    useEffect(() => {
        console.log(forecastingForm.dataSplit);
    }, [forecastingForm.dataSplit]);
    
    return (
        <Segment id='timeseries-container' raised textAlign='center'> 
            <HighchartsReact
                highcharts={Highcharts}
                containerProps={{className: "chartContainer"}}
                allowChartUpdate={true}
                immutable={false}
                updateArgs={[true, true, true]}
                options= {{
                    chart: {
                        type: 'line',
                        height: '300px',
                        marginTop: 10,
                        paddingTop: 0,
                        marginBottom: 50,
                        plotBorderWidth: 1,
                    },
                    xAxis: {
                        ordinal: false,
                        type: "datetime",
                        tickPixelInterval: 100,
                        title: {
                            // text: 'Date',
                            style: {
                            fontSize: "1.1em"
                            },
                        },
                    },
                    yAxis: {
                        title: {
                            text: timeSeriesMeasureCol,
                            style: {
                            fontSize: "1.1em"
                            }
                        },
                    },
                    colorAxis: {
                        min: 0,
                        minColor: '#FFFFFF',
                        maxColor: '#2185d0'
                    },
                    plotOptions: {
                        line: {
                            cursor: 'pointer',
                        },
                    },
                    legend: {
                        enabled: false
                    },
                    title: null,
                    series:[{
                        type: 'line',
                        name: 'Time Series',
                        data: seriesData,
                        zoneAxis: 'x',
                        zones: getZones(),
                      },
                      {
                        type: 'line',
                        name: 'Predictions',
                        data: predictionSeriesData,
                        color: 'red'
                        }
                    ],
                    tooltip: {
                        formatter: function () {
                          // The first returned item is the header, subsequent items are the
                          // points
                          return ['<b>' + new Date(this.x) + '</b>'].concat(
                            this.points
                              ? this.points.map(function (point) {
                                  let ss = `<div><span style="color: ${point.color}; font-size: 12px; margin-right:5px;">●</span>  ${
                                    point.series.name
                                  }: ${point.y.toFixed(2)}</div>`;
                                  ss += point.point.tt ? `<br>${point.point.tt}</br>` : '';
                                  return ss;
                                })
                              : []
                          );
                        },
                        split: true,
                      },
                }}
            />
        </Segment>   
    );
}