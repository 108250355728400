import React, { Dispatch, SetStateAction } from 'react';
import { IForecastingForm } from 'app/shared/model/forecasting.model';
import { Segment, Header, Dropdown } from 'semantic-ui-react';
import { IDataset } from 'app/shared/model/dataset.model';


export interface IFeaturesProps {
  dataset: IDataset,
  timeSeriesMeasureCol: string, 
  forecastingForm: IForecastingForm,
  setForecastingForm: Dispatch<SetStateAction<IForecastingForm>>,
}

const Features = (props: IFeaturesProps) => {
  const { dataset, timeSeriesMeasureCol, forecastingForm, setForecastingForm } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);

  const [personName, setPersonName] = React.useState<string[]>([]);

  const handleChange = value => {
    console.log(value);
    setForecastingForm(state => ({
      ...state,
      features: {
        ...state.features,
        columnFeatures: value,
      },
    }));
  };

  return (
    <Segment className={'selected-meas'} style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '2rem', textAlign: 'center', border: 'none' }}>
      <Segment style={{ display: 'flex', gap: '1rem', alignItems: 'center', width: '100%', margin: 'auto' }}>
          <Header as='h5' style={{ fontSize: '16px' , marginRight: 'auto' }}>
            {`${timeSeriesMeasureCol}`}
          </Header>
          {forecastingForm.features.columnFeatures && (
            <Dropdown
              placeholder='Select Features'
              multiple
              selection
              options={dataset.headers.filter(h => timeSeriesMeasureCol !== h && timeSeriesMeasureCol !== h).map(name => ({
                key: name,
                text: name,
                value: name,
              }))}
              value={
                forecastingForm.features.columnFeatures
              }
              onChange={(e, { value }) => handleChange(value)}
            />
          )}
      </Segment>
    </Segment>
  );
};

export default Features;
