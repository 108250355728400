import { reset, updateFilters, updateTimeRange } from "app/modules/visualizer/visualizer.reducer";
import { IDataset } from "app/shared/model/dataset.model";
import _ from 'lodash';
import React, { useState } from 'react';
import {
  Accordion,
  Divider,
  Dropdown,
  Icon,
  Image,
  List,
  Popup,
  Segment,
  Button,
} from "semantic-ui-react";

import {
  DatesRangeInput
} from 'semantic-ui-calendar-react';

export interface IVisControlProps {
  dataset: IDataset,
  datasets: IDataset[],
  facets: any,
  groupByCols: string[],
  categoricalFilters: any,
  updateFilters: typeof updateFilters,
  reset: typeof reset,
  copyCurrentURL: () => void,
  updateTimeRange: typeof updateTimeRange,
}

export const VisControl = (props: IVisControlProps) => {
  const {dataset, datasets, categoricalFilters, facets, copyCurrentURL} = props;

  const [expandedFilter, setExpandedFilter] = useState(null);
  const [dateRange, setDateRange] = useState('');

  const [activeDate, setActiveDate] = useState(0);

  const handleAccordionClick = (e, data) => {
    e.stopPropagation();
    const {index} = data;
    const newIndex = expandedFilter === index ? null : index;
    setExpandedFilter(newIndex);
  };

  const handleFilterChange = (dimIndex) => (e, {value}) => {
    e.stopPropagation();
    const filters = {...categoricalFilters};
    filters[dimIndex] = value === "" ? null : value;
    props.updateFilters(dataset.id, filters);
  };

  const removeFilter = (dimIndex) => () => {
    const filters = {...categoricalFilters};
    props.updateFilters(dataset.id, _.omit(filters, dimIndex));
  };
  
  const handleRangeListClick = (id) => {
    setActiveDate(id);
    const now = Date.now();
    let t;
    const start = 30 * 24 * 60 * 60 * 1000; // one month
    switch(id){
      case 0:
        t = {from: dataset.timeMin, to: now};
        break;
      case 1:
        t = {from: now - start, to: now };
        break;
      case 2:
        t = {from: now - (start / 2), to: now }; // ~2 weeks
        break;
      case 3:
        t = {from: now - (start / 4), to: now }; // ~1 week
        break;
      case 4:
        t = {from: now - (start / 10), to: now }; // 3 days
        break;
      case 5:
        t = {from: now - (start / 30), to: now }; // 1 day
        break;
      default:
        t = {from: dataset.timeMin, to: now};
    }
    setDateRange("");
    props.updateTimeRange(dataset.id, t);
  }

  const handleDateChange = (event, {name, value}) => {
    setDateRange(value);
    const dates = (value.split(" - "));
    if (dates.length === 2 && dates[1] !== ""){
      const parts1 = dates[0].split('-');
      const parts2 = dates[1].split('-');
      const dates1 = new Date(parts1[2], parts1[1] - 1, parts1[0]);
      const dates2 = new Date(parts2[2], parts2[1] - 1, parts2[0]);
      const t = {from: dates1.getTime(), to: dates2.getTime()}
      props.updateTimeRange(dataset.id, t);
    }
  }


  const filterDropdowns = facets &&
    <div className='filters'>
      <Dropdown scrolling style={{padding: '10px', marginTop: '0'}}
                className='icon left' text='Select one or more filters'
                icon='filter' fluid
                floating
                labeled
                button>
        <Accordion fluid as={Dropdown.Menu}>
          {dataset.dimensions
            .map((dimension, i) => (
              facets[dimension] && <Dropdown.Item
                key={i}
                onClick={handleAccordionClick}
                index={dimension} className='dimension-filter'>
                <Accordion.Title
                  onClick={handleAccordionClick}
                  index={dimension}
                  className="filter-accordion-title"
                  active={expandedFilter === dimension}
                  content={dimension}
                  icon="sort down"
                />
                <Accordion.Content active={expandedFilter === dimension}>
                  <List relaxed verticalAlign="middle">
                    {facets[dimension].map((value, index) => (
                      <List.Item onClick={handleFilterChange(dimension)} value={value} key={index}>
                        <List.Icon
                          name={value === categoricalFilters[dimension] ? 'dot circle outline' : 'circle outline'}
                        />
                        <List.Content>
                          <List.Description
                            className="dropdown-description">{value}</List.Description>
                        </List.Content>
                      </List.Item>
                    ))}
                  </List>
                </Accordion.Content>
              </Dropdown.Item>
            ))}
        </Accordion></Dropdown></div>;


  const removeFilters =
    <div className="remove-filters">
      {categoricalFilters && _.map(categoricalFilters, (value, dim) => {
        return (
          <div className="remove-filter" key={dim}>
            <Icon link name='close' onClick={removeFilter(dim)}/>
            <span className="remove-filter-dim-label">{dim} / </span>
            <span className="remove-filter-value">
                    {value}
                  </span>
          </div>
        );
      })}
    </div>;

  return datasets && <Segment id='vis-control' padded='very' raised>
    {/* <Image href='/' src='./content/images/vf_logo.png' style={{width: 300, paddingBottom: "10px"}}/> */}
    {/* <Button icon onClick={() => props.setMapType('clusterMap')}>
      <Icon name="chart line" />
    </Button>
    <Button icon onClick={() => props.setMapType('heatMap')}>
      <Icon name="chart bar" />
    </Button> */}
    <h4>
      Filtering
    </h4>
    {filterDropdowns}
    {removeFilters}
    <h4>
      Time Range
    </h4>
    <List className="ui horizontal link list">
      <List.Item  as='a' active={activeDate === 0} onClick={(e) => handleRangeListClick(0)}>All</List.Item>
      <List.Item  as='a' active={activeDate === 1} onClick={(e) => handleRangeListClick(1)} >1M</List.Item>
      <List.Item  as='a' active={activeDate === 2} onClick={(e) => handleRangeListClick(2)} >2W</List.Item>
      <List.Item  as='a' active={activeDate === 3} onClick={(e) => handleRangeListClick(3)}>1W</List.Item>
      <List.Item  as='a' active={activeDate === 4} onClick={(e) => handleRangeListClick(4)}>3D</List.Item>
      <List.Item  as='a' active={activeDate === 5} onClick={(e) => handleRangeListClick(5)}>1D</List.Item>
    </List>
    <div style={{width:'100%'}}>
      <DatesRangeInput
        name="datesRange"
        minDate={new Date(dataset.timeMin)}
        maxDate={new Date(Date.now())}
        placeholder="Custom Date Range"
        value={dateRange}
        closable
        style={{padding:0, width:'100%'}}
        iconPosition="left"
        onChange={handleDateChange}
      />
    </div>
    <Divider/>
    <Popup
      on="click"
      content="Copied to clipboard!"
      style={{
        fontSize: '12px'
      }}
      trigger={<a onClick={copyCurrentURL}><Icon name='linkify'/>Share map</a>}
      basic
    />
  </Segment>
};


export default VisControl;